import { createSlice } from '@reduxjs/toolkit';

export const marketShareSlice = createSlice({
  name: 'marketShare',
  initialState: {
    centreStatus: ['OPEN', 'CLOSED'],
    showLandlordIntel: false,
    landLordIntelSelected: null,
  },
  reducers: {
    addStatus: (state, action) => {
        state.centreStatus.push(action.payload);
    },
    removeStatus: (state, action) => {
        state.centreStatus = state.centreStatus.filter(status => status !== action.payload);
    },
    showLandlordIntel: (state) => {
        state.showLandlordIntel = true;
    },
    hideLandLordIntel: (state) => {
        state.showLandlordIntel = false;
    },
    setLandLordIntelSelected: (state, action) => {
      state.landLordIntelSelected = action.payload;
    },
    clearLandLordIntelSelected: (state) => {
      state.landLordIntelSelected = null;
    },
  },
});

export const { addStatus, removeStatus, showLandlordIntel, hideLandLordIntel, setLandLordIntelSelected,
    clearLandLordIntelSelected  } = marketShareSlice.actions;

export default marketShareSlice.reducer;
