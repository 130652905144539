import React, { useState, useEffect } from 'react';
import { Modal, Button, Input, message } from 'antd';
import JsonView from '@uiw/react-json-view';
import { SQUELETON, validateTemplate } from './user-template-squeleton';

const { TextArea } = Input;

const TemplateModal = ({ isVisible, onClose, template, onSave, isCreating }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [editedTemplate, setEditedTemplate] = useState(JSON.stringify(template, null, 2));
    const [jsonViewKey, setJsonViewKey] = useState(Date.now()); // Unique key for JsonView


    useEffect(() => {
        if (template && !isCreating) {
            setEditedTemplate(JSON.stringify(template, null, 2));
        } else if (isCreating) {
            setEditedTemplate(JSON.stringify(SQUELETON, null, 2));
        }
    }, [template, isCreating]);

    useEffect(() => {
        setJsonViewKey(Date.now());
        if (!isVisible) {
            setIsEditing(false);
        } else if (isCreating) {
            setIsEditing(true);
        }
    }, [isVisible, isCreating]);

    const handleEdit = () => {
        setIsEditing(true);
    };

    const handleSave = () => {
        try {
            const parsedTemplate = JSON.parse(editedTemplate);
            try {
                // validateTemplate(parsedTemplate); // disble for now.
                onSave(parsedTemplate);
                setIsEditing(false);
                onClose();
                message.success('Template saved successfully');
            }catch(e){
                message.error(`Template structure is invalid: ${e.errors.join(', ')}`);
            }
        } catch (error) {
            console.log(error);
            message.error('Invalid JSON format');
        }
    };

    const handleCancel = () => {
        setIsEditing(false);
        onClose();
    };

    return (
        <Modal
            title="User Template"
            open={isVisible}
            onCancel={handleCancel}
            width="80vw"
            height="75vh"
            style={{ top: 20 }}
            bodyStyle={{ overflowY: 'auto', height: 'calc(100% - 80px)' }}
            footer={isEditing ? [
                <Button key="save" type="primary" onClick={handleSave}>
                    Save
                </Button>,
                <Button key="cancel" onClick={handleCancel}>
                    Cancel
                </Button>
            ] : [
                <Button key="edit" type="primary" onClick={handleEdit}>
                    Edit
                </Button>,
                <Button key="close" onClick={onClose}>
                    Close
                </Button>
            ]}
        >
            {isEditing ? (
                <TextArea
                    rows={28}
                    value={editedTemplate}
                    onChange={(e) => setEditedTemplate(e.target.value)}
                    style={{ height: 'calc(100% - 80px)' }}
                />
            ) : (
                <JsonView  key={jsonViewKey}  value={template} displayDataTypes={false} collapsed={3} />
            )}
        </Modal>
    );
};

export default TemplateModal;
