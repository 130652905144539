import moment from 'moment';
import { tooltipBackground } from '../../../../utils/colors';

export const MarkdetDemmandTooltip = ({ active, payload, label }) => {

    return (
        <div>
            {active ? (
                <div className="custom-tooltip" style={{
                    background: tooltipBackground,
                    paddingTop: "10px",
                    paddingBottom: "5px",
                    paddingLeft: "20px",
                    paddingRight: "20px",
                    borderRadius: "10px"
                }}>
                    <p style={{
                        color: '#FFFFFF',
                        fontSize: "12px"
                    }}>Month Date: <b>{`${moment(label).format('MMM YYYY')}`}</b></p>
                    <p style={{
                        color: '#FFFFFF',
                        fontSize: "12px"
                    }}>Level of enquired desk: <b>{`${payload[0].value.toLocaleString('en-US', {maximumFractionDigits:0})}`}</b></p>
                </div>
            ) : null}
        </div>
    );
}
