import {createSlice} from '@reduxjs/toolkit';
import {userService} from './user.service';
import { Mixpanel } from '../../mixpanel';

const initialState = {
  isLoading: false,
  isLoggedIn: false,
  error: null,
  profile: {},
  userTemplate:{}
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setLoading: (state) => {
      state.isLoading = true;
    },
    loginSuccess: (state, action) => ({
      ...state,
      isLoading: false,
      profile: action.payload,
      isLoggedIn: true,
      error: null
    }),
    getTemplateSuccess: (state, action) => ({
        ...state,
        userTemplate: action.payload.template,
        isTemporalTemplate: action.payload.isTemporal,
        temporalUserEmail: action.payload.temporalUserEmail
    }),
    loginError: (state, action) => ({
      ...initialState,
      error: action.payload
    }),
    resetState: () => initialState
  }
});

const {loginSuccess, loginError, getTemplateSuccess} = userSlice.actions;
export const {resetState} = userSlice.actions;

export const logout = () => async (dispatch) => {
    userService.logout();
    Mixpanel.reset();
    dispatch(resetState());
};

export const logoutAuth0 = () => async (dispatch) => {
  dispatch(logout());
};

// const delay = (ms) => new Promise((resolve, reject) => setTimeout(() => resolve(), ms));

export const loginAuth0 = (token, userAuth) => async (dispatch) => {
  dispatch(userSlice.actions.setLoading());
  // localStorage.setItem('token', token);
  // await delay(1500);
  await userService
    .login({'email': userAuth['email'], 'name': userAuth['name'], 'token': token})
    .then(userService.me)
    .then((user) => {
      localStorage.setItem('userStatus', user.status);
      if (user.status === 'DEMO' || user.status === 'ADMIN') {
        return userService.loginDemo({email: 'dciccale@gmail.com', password: 'C67h4yNpLDxam3'});
      }
    })
    .then(userService.template)
    .then((user) =>
        dispatch(getTemplateSuccess(user))
    )
    .then(userService.me)
    .then((user) => {
      dispatch(loginSuccess({...userAuth, ...user}));
      Mixpanel.identify(user.email);
      Mixpanel.track('Successful login');
    })
    .catch((err) => {
      dispatch(logout());
      dispatch(loginError(err.message));
    });
};

export const login = ({email, password, from}) => async (dispatch) => {
  dispatch(userSlice.actions.setLoading());
  // await delay(1500);
  await userService
    .login({email, password})
    .then(userService.me)
    .then((user) => {
      localStorage.setItem('userStatus', user.status);
      if (user.status === 'DEMO' || user.status === 'ADMIN') {
        return userService.loginDemo({email: 'dciccale@gmail.com', password: 'C67h4yNpLDxam3'});
      }
    })
    .then(userService.me)
    .then((user) => {
      dispatch(loginSuccess(user));
      Mixpanel.identify(user.email);
      Mixpanel.track('Successful login');
    })
    .catch((err) => {
      dispatch(logout());
      dispatch(loginError(err.message));
    });
};

export const signup = (data) => async (dispatch) => {
  dispatch(userSlice.actions.setLoading());
  await userService
    .signup(data)
    .then((user) => {
      dispatch(loginSuccess(user))
      localStorage.setItem('userStatus', user.status);
    })
    .then(userService.me)
    .then((user) => {
      Mixpanel.people.set(user);
      Mixpanel.track('Successful signup');
    })
    .catch((err) => {
      userService.logout();
      dispatch(loginError(err.message));
    });
};

export const forgotPassword = (data) => async (dispatch) => {
  dispatch(userSlice.actions.setLoading());
  await userService
    .forgotPassword(data)
    .then(() => dispatch(resetState()))
    .catch((err) => {
      dispatch(loginError(err.message));
    });
};

export const getUser = (token) => async (dispatch) => {
  await userService
    .me()
    .then((user) => dispatch(loginSuccess(user)))
    .catch((err) => {
      dispatch(logout());
      dispatch(loginError(err.message))
    });
};

export const getUserTemplate = (token) => async (dispatch) => {
    await userService
      .template()
      .then((user) => dispatch(getTemplateSuccess({template: user, isTemporal: false})))
      .catch((err) => {}); // no need to handle the error, we just don't show any tab
};

export const setTemporalTemplate = (template, email) => (dispatch) => {
    dispatch(getTemplateSuccess({template, isTemporal: true, temporalUserEmail: email}));
};

export default userSlice.reducer;
