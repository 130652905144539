import React, { useEffect, useCallback } from 'react';
import { getUser, getUserTemplate } from './modules/user/user.slice';
import { userService } from './modules/user/user.service';
import { useDispatch, useSelector } from 'react-redux';
import { PublicRoutes, PrivateRoutes, AdminRoutes } from './routes';
import {IntlProvider} from 'react-intl';
import { CssBaseline, ThemeProvider, StyledEngineProvider } from '@mui/material';
import cartoTheme from './cartoTheme';
import spinner from './loading_spinner.gif'
import './App.less';

function App() {
    const dispatch = useDispatch();
    const token = userService.getToken();
    const userState = useSelector(state => state.user);
    const { isLoggedIn, profile, error } = userState;

    const maybeFetchUser = useCallback(() => {
        if (!profile.email && token) {
          dispatch(getUser(token));
          dispatch(getUserTemplate(token));
        }
    }, [dispatch, token, profile.email]);

    useEffect(maybeFetchUser, [maybeFetchUser])

    const antIcon = <img src={spinner} alt="loading..." style={{"background-color":"white"}}/>
    const Loading = () => <div className="app-loading">{antIcon}</div>;

    const getRoutes = () => {
        if (isLoggedIn) {
          return <PrivateRoutes profile={profile} />;
        }
        return <PublicRoutes />;
    };

    const messages = {
        'c4r.widgets.category.unlock': 'Clear selection',
        'c4r.widgets.category.searchInfo': 'Filter by Brands'
    };

    return (

        <StyledEngineProvider injectFirst>
            <IntlProvider locale="en" messages={messages}>
                <ThemeProvider theme={cartoTheme}>
                    <CssBaseline />
                    <style>{'body { background-color: #EBEBEB; }'}</style>
                    <div>
                        {token && !profile.email && !error ? <Loading /> : getRoutes() }
                    </div>
                </ThemeProvider>
            </IntlProvider>
        </StyledEngineProvider>

    );
}

export default App;
