import api from '../../api';

export const adminService = {
  deleteUser,
  updateUser,
  getUserTemplate,
  updateUserTemplate,
};

function deleteUser(userId) {
  return api
    .delete(`admin/users/${userId}`)
    .then((res) => res.data);
}

function updateUser(userId, data) {
  return api
    .put(`admin/users/${userId}`, data)
    .then((res) => res.data);
}

function getUserTemplate(userId) {
  return api
    .get(`admin/users/${userId}/template`)
    .then((res) => res.data);
}

function updateUserTemplate(userId, data) {
    return api
      .put(`admin/users/${userId}/template`, data)
      .then((res) => res.data);
  }
