import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import BreadcrumbRouter from './breadcrumb-router.component';
import {Typography, Row, Col, Form, Input, Button} from 'antd';
import {integrationService} from './integration.service';
import {getUser} from '../user/user.slice';
import { useNavigate } from 'react-router-dom';
import { Mixpanel } from '../../mixpanel';
import api from '../../api';
import { useDropzone } from 'react-dropzone';

const {Text} = Typography;

function Manual() {
    const [errorMessage, setErrorMessage] = useState('');
    const [isLoading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const [file, setFile] = useState(null)
    const [fileName, setFileName] = useState('');
    const [uploading, setUploading] = useState(false)
    const [uploaded, setUploaded] = useState(false)
    const userState = useSelector((state) => state.user);
    const userName = userState.profile.name;
    const integrations = userState.profile.integrations;
    const layout = {labelCol: {span: 12}};
    const [error, setError] = useState(null);
    const [type, setType] = useState(null);
    const acceptedFileTypes = ['.csv', '.json', '.xls', '.xlsx'];
    const navigate = useNavigate();


    const manualArray = integrations.filter((integration) => integration.type === 'manual');
    const manualIntegration = manualArray.length > 0 ? manualArray[0] : null;

    async function handleFinish(values) {
        values['organization'] = values['organization'].replace(/\s+/g, '-').toLowerCase();
        setLoading(true);
        const isUploadSuccessful = await uploadFile();
        if (isUploadSuccessful && !manualIntegration) {
            integrationService.connect(Object.assign({type: 'manual'}, {credentials: values}))
            .then(() => dispatch(getUser()))
            .then(() => navigate('/setup', { replace: true }))
            .catch((err) => setErrorMessage(err.response.data.message))
            .finally(() => setLoading(false));
        } else if (isUploadSuccessful && manualIntegration) {
            integrationService.update(manualIntegration._id, {credentials: values})
            .then(() => dispatch(getUser()))
            .then(() => navigate('/setup', { replace: true }))
            .catch((err) => setErrorMessage(err.response.data.message))
            .finally(() => setLoading(false));
        } else {
            setLoading(false)
            setFile(null);
            setFileName('');
            setUploading(false);
        }
    }
    useEffect(() => {
        Mixpanel.track('Integrations - Manual');
    });

    function handleChange(acceptedFiles) {
        const fileType = acceptedFiles[0].name.split(".")
        if(acceptedFileTypes.includes(`.${fileType[fileType.length-1]}`)){
            const tempFile = new File([acceptedFiles[0]], `ManualUpload-${userName}.${fileType[fileType.length-1]}`,  {
                type: acceptedFiles[0].type,
            });
            setFile(tempFile)
            setFileName(acceptedFiles[0].name);
            setType(null)
        }else{
            setType("File type not accepted. Only csv, json, xls, xlsx files are accepted.")
        }
    }

    const uploadFile = () => {
        if (file) {
            setUploading(true);
            const formData = new FormData();
            formData.append('file', file);
                const config = {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                };
            return api.post('/connectors/manual/upload_file', formData, config)
                .then((response) => {
                    console.log(response.data.message);
                    setUploading(false);
                    setUploaded(true);
                    setError(null);
                    return true;
                })
                .catch((error) => {
                    console.error(error);
                    setError(`Error - ${error.response.data.error} - Try again, or contact the support email. `);
                    return false;
                }
            );
        } else {
          setError("You need to select a file before uploading");
           return false; // No file selected, return false
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        uploadFile();
    };

    const handleRemove = () => {
        setFile(null);
        setFileName('');
        setUploading(false);
        setError(null);
        setUploaded(false);
    }

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        accept: acceptedFileTypes.join(','),
        onDrop: acceptedFiles => {
            if(acceptedFiles.length > 0) {
                handleChange(acceptedFiles);
                setError(null);
            }
        },
    });

    return (
        <>
            <BreadcrumbRouter / >

            <h2>Manual Integration</h2>
            <p>In case you don't have a PMS or it's not in the list, we can manually upload your data.</p>
            <p>Follow the instructions to setup your account manually:</p>
            <h1><strong>Step 1 - </strong>Download our template: <a href="https://drive.google.com/uc?export=download&id=1tBiwD8c44vEmpX0QdlAeT95oWByvJybC" target="_blank" rel="noreferrer">Template-CoworkIntel</a></h1>
            <p><Text type="danger">{errorMessage}</Text></p>
            <Row>
                <Col >
                    <div className="App">
                        <form onSubmit={handleSubmit}>
                            <h1><strong>Step 2 - </strong>Please, complete the template with your data and upload it:</h1>
                            <div {...getRootProps({className: 'dropzone'})}>
                                <style jsx>
                                    {`
                                    .dropzone {
                                        text-align: center;
                                        padding: 20px;
                                        border: 3px blue dashed;
                                        width: 60%;
                                        margin: auto;
                                    }`
                                    }
                                </style>
                                <input {...getInputProps()}/>
                                <div>
                                    {!isDragActive && <p>Drag and drop your files here, or click to select files</p>}
                                    {isDragActive && <p>Drop your files here</p>}
                                </div>
                            </div>
                            {type && <p style={{ color: 'red' }}>{type}</p> }
                            <p>{fileName}</p>
                            {fileName && <Button type="danger" onClick={handleRemove}>Remove file</Button>}
                            <p></p>
                            {uploading && <p>Loading...</p> }
                            {uploaded && <p style={{ color: 'green' }}>File upload successful</p> }
                        </form>
                        {error && <p style={{ color: 'red' }}>{error}</p> }
                    </div>
                    <p></p>
                    <h3><strong>Step 3 - </strong>Please, Input your Coworking Name:</h3>
                    <Form {...layout} name="connect" onFinish={handleFinish}>
                        <Form.Item
                            name="organization"
                            rules={[{required: true, message: 'Please input the name of your coworking'}]}
                        >
                            <Input placeholder="Coworking Name" defaultValue={manualIntegration ? manualIntegration.credentials.organization : undefined}/>
                        </Form.Item>
                        <p><small>Learn about how Coworkintel, Inc. will handle your data by reviewing  our <a href="https://coworkintel.com/terms.html" target="_blank" rel="noreferrer">terms of service</a>, <a href="https://coworkintel.com/privacy-policy.html" target="_blank" rel="noreferrer">privacy policies</a> and <a href="https://coworkintel.com/policy.html" target="_blank" rel="noreferrer">competitive set policy</a>. You can contact us at support@coworkintel.com if you want to remove your account.</small></p>
                        <Form.Item>
                            <Button
                                type="primary"
                                htmlType="submit"
                                loading={isLoading}
                                disabled={!fileName}
                            >
                                Request Manual Integration
                            </Button>
                            {!fileName && <p style={{ color: 'grey' }}>*You must upload a document to send the request</p> }
                        </Form.Item>
                    </Form>
                </Col>
            </Row>
        </>
    );
}

export default Manual;
