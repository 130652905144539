import React, {useEffect} from 'react';
import { Mixpanel } from '../../mixpanel';

function Profile({profile}) {
  useEffect(() => {
    Mixpanel.track('Account - Profile');
  });

  return (
    <>
      <h2>Profile</h2>

      <p>Name: {profile.name}</p>
      <p>Account email: {profile.email}</p>
    </>
  );
}

export default Profile;
