import { createSlice } from '@reduxjs/toolkit';

export const flexArbitrageSlice = createSlice({
  name: 'flexArbitrage',
  initialState: {
    flexArbitrageRatio: [0, 10],
  },
  reducers: {
    changeRatio: (state, action) => {
      state.flexArbitrageRatio = action.payload;
    },
  },
});

export const { changeRatio } = flexArbitrageSlice.actions;

export default flexArbitrageSlice.reducer;
