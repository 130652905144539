import { useSelector, useDispatch } from 'react-redux';
import { CartoLayer } from '@deck.gl/carto';
import { selectSourceById, updateLayer } from '@carto/react-redux';
import { useCartoLayerProps } from '@carto/react-api';
import { LEGEND_TYPES } from '@carto/react-ui';
import { hexToRGB, demorgaphicPallette } from '../../../../utils/colors';
import htmlForFeature, { FORMATTER_TYPES } from '../../../../utils/htmlForFeature';

export const CENSUS_POPULATION_LAYER_ID = 'censusPopulationLayer';

export const CATEGORY_COLORS = {
    '0 - 0.5K' : hexToRGB(demorgaphicPallette[0], 204),
    '0.5K - 1K' : hexToRGB(demorgaphicPallette[1], 204),
    '1K - 1.5K' : hexToRGB(demorgaphicPallette[2], 204),
    '1.5K - 2K' : hexToRGB(demorgaphicPallette[3], 204),
    '2K - 2.5K' : hexToRGB(demorgaphicPallette[4], 204),
    '+ 2.5K'  : hexToRGB(demorgaphicPallette[5], 204),
};

const DATA = Object.entries(CATEGORY_COLORS).map((elem) => {
    return { color: elem[1], label: elem[0] };
});

export default function CensusPopulationLayer() {
    const dispatch = useDispatch();
    const { censusPopulationLayer } = useSelector((state) => state.carto.layers);
    const source = useSelector((state) => selectSourceById(state, censusPopulationLayer?.source));
    const demographics = useSelector((state) => state.demographics);
    const cartoLayerProps = useCartoLayerProps({ source });

    const layerConfig = {
        title: 'Census Population',
        visible: demographics.censusPopulationLayerVisible,
        switchable: true,
        legend: {
            collapsible: true,
            collapsed: !demographics.censusPopulationLayerVisible,
            type: LEGEND_TYPES.CATEGORY,
            labels: DATA.map((data) => data.label),
            colors: DATA.map((data) => data.color),
        },
    };

    if (censusPopulationLayer && source) {
        return new CartoLayer({
            ...cartoLayerProps,
            id: CENSUS_POPULATION_LAYER_ID,
            geoColumn: 'geom',
            lineWidthMinPixels: 1,
            visible: censusPopulationLayer.visible,
            getFillColor: (object) => {
                const value = Math.floor(object.properties.total_population || 0).toFixed(0);
                return value < 500 && value >= 0 ? hexToRGB(demorgaphicPallette[0], 204) :
                    value < 1000 && value >= 500 ? hexToRGB(demorgaphicPallette[1], 204) :
                    value < 1500 && value >= 1000 ? hexToRGB(demorgaphicPallette[2], 204) :
                    value < 2000 && value >= 1500 ? hexToRGB(demorgaphicPallette[3], 204) :
                    value < 2500 && value >= 2000 ? hexToRGB(demorgaphicPallette[4], 204) :
                    value >= 2500 ? hexToRGB(demorgaphicPallette[8], 204) : hexToRGB(demorgaphicPallette[8], 204);
            },
            getLineColor: (object) => {
                const value = Math.floor(object.properties.total_population || 0).toFixed(0);
                return value < 500 && value >= 0 ? hexToRGB(demorgaphicPallette[0], 204) :
                    value < 1000 && value >= 500 ? hexToRGB(demorgaphicPallette[1], 204) :
                    value < 1500 && value >= 1000 ? hexToRGB(demorgaphicPallette[2], 204) :
                    value < 2000 && value >= 1500 ? hexToRGB(demorgaphicPallette[3], 204) :
                    value < 2500 && value >= 2000 ? hexToRGB(demorgaphicPallette[4], 204) :
                    value >= 2500 ? hexToRGB(demorgaphicPallette[8], 204) : hexToRGB(demorgaphicPallette[8], 204);
            },
            pickable: true,
            onDataLoad: (data) => {
                dispatch(
                    updateLayer({ id: CENSUS_POPULATION_LAYER_ID, layerAttributes: { ...layerConfig } })
                );
                cartoLayerProps.onDataLoad && cartoLayerProps.onDataLoad(data);
            },
            onDataError: (error) => { console.log(error) },
            onHover: (info) => {
                if (info?.object) {
                    info.object = {
                        html: htmlForFeature({
                            feature: info.object,
                            formatters: [
                                {
                                    type: FORMATTER_TYPES.NUMBER,
                                    column: 'total_population',
                                }
                            ],
                            includeColumns: [
                                {'originalName':'county_name', 'alias' : 'County'},
                                {'originalName':'state_name', 'alias' : 'State'},
                                {'originalName':'total_population', 'alias' : 'Population'},
                            ],
                         }),
                        style: {},
                    };
                }
            },
        });
    }
}
