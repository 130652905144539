import React from 'react';
import { Layout } from 'antd';
import { Outlet } from 'react-router-dom';


const { Content } = Layout;

function Integrations({integrations}) {

  return (

    <Layout style={{ height: '100%', backgroundColor: '#EBEBEB' }}>
        <h2>Integrations</h2>
        <Content style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <Outlet />
        </Content>
    </Layout>
  );
}

export default Integrations;
