import { useSelector, useDispatch } from 'react-redux';
import { CartoLayer } from '@deck.gl/carto';
import { selectSourceById, updateLayer } from '@carto/react-redux';
import { useCartoLayerProps } from '@carto/react-api';
import htmlForFeature from '../../../../utils/htmlForFeature';
import { LEGEND_TYPES } from '@carto/react-ui';
import { brandPurple, brandPink, hexToRGB } from '../../../../utils/colors';

export const MAP_LAYER_ID = 'mapLayer';

export const CATEGORY_COLORS = {
    'Open Centres': hexToRGB(brandPurple, 204),
    'Recently closed (last 24 mo)': hexToRGB(brandPink, 204),
};

const DATA = Object.entries(CATEGORY_COLORS).map((elem) => {
    return { color: elem[1], label: elem[0] };
});

export const marketShareLayerConfig = {
    type: LEGEND_TYPES.CATEGORY,
    labels: DATA.map((data) => data.label),
    colors: DATA.map((data) => data.color),
};

const layerConfig = {
    title: 'Centre status',
    visible: true,
    switchable: false,
    legend: marketShareLayerConfig,
};

export default function MarketShareLayer() {
    const dispatch = useDispatch();
    const { mapLayer } = useSelector((state) => state.carto.layers);
    const source = useSelector((state) => selectSourceById(state, mapLayer?.source));
    const landlordVisibility = useSelector((state) => state.marketShare.showLandlordIntel);
    const cartoLayerProps = useCartoLayerProps({ source });

    if (mapLayer && source) {
        return new CartoLayer({
            ...cartoLayerProps,
            id: MAP_LAYER_ID,
            pointRadiusMinPixels: 8,
            pickable: true,
            visible: !landlordVisibility,
            loadOptions: {
                fetch: {
                    headers: {
                        'cache-control': 'no-cache, max-age=0'
                    }
                }
            },
            getFillColor: (object) => {
                if (object.properties.centre_state_clean === 'OPEN') {
                    return hexToRGB(brandPurple, 204);
                }
                return hexToRGB(brandPink, 204);
            },
            getLineColor: (object) => {
                if (object.properties.centre_state_clean === 'OPEN') {
                    return hexToRGB(brandPurple, 204);
                }
                return hexToRGB(brandPink, 204);
            },
            onDataLoad: (data) => {
                dispatch(
                    updateLayer({ id: MAP_LAYER_ID, layerAttributes: { ...layerConfig } })
                );
                cartoLayerProps.onDataLoad && cartoLayerProps.onDataLoad(data);
            },
            onDataError: (error) => { },
            onHover: (info) => {
                if (info?.object) {
                    if (info.object.properties.centre_state_clean === 'CLOSED') { return; }

                    info.object = {
                        html: htmlForFeature({
                            feature: info.object,
                            includeColumns: [
                                {'originalName':'operator_name', 'alias' : 'Operator Name'},
                                {'originalName':'parent_operator_name', 'alias' : 'Parent Operator Name'},
                                {'originalName':'address', 'alias' : 'Address'},
                                {'originalName':'district_segmentation_name', 'alias' : 'District'},
                                {'originalName':'postcode', 'alias' : 'Postcode'},
                                {'originalName':'country', 'alias' : 'Country'},
                                {'originalName':'market', 'alias' : 'Market'},
                                /*{'originalName':'total_size_lm', 'alias' : 'Total Size LM'},
                                {'originalName':'total_size_ws', 'alias' : 'Total Size WS'},*/
                                {'originalName':'website_address', 'alias' : 'Website Address'},
                            ],
                         }),
                        style: {},
                    };
                }
            },
        });
    }
}
