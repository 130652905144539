import React, {useState, useEffect, useCallback} from 'react';
import api from '../../api';
import { useLocation } from 'react-router-dom';
import { Mixpanel } from '../../mixpanel';
import spinner from './../../loading_spinner.gif'
import { parseQueryString } from '../../utils/utils';

function MetabaseSubtab() {
    const [iframeUrl, setIframeUrl] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const location = useLocation();
    const path = location.pathname;
    const { currentTab, subtab} = location.state;

    const iframeId = subtab.content.id;
    const title = path.includes('Deals') ? `Deals - ${subtab.title}` : subtab.title;
    const filters = subtab.filters;

    useEffect(() => {
        localStorage.setItem("currentDash", `${currentTab.tab}:dashboard:${title}:${iframeId}`);
    }, [currentTab.tab, iframeId, title]);

    const handleMessage = useCallback((event) => {
        if (event.origin !== 'https://db.coworkintel.com' || !event.data?.metabase || event.data.metabase.type !== 'location' || event.data.metabase.location.search === '') {
            return;
        }

        const currentIframeStorageKey = localStorage.getItem("currentDash");
        if (currentIframeStorageKey) {
            const userFilters = event.data.metabase.location.search;
            localStorage.setItem(currentIframeStorageKey, userFilters);
            Mixpanel.trackProperty(title, {iframeId: iframeId, fixedFilters: filters, selectedFilters: parseQueryString(userFilters.replace('?', ''))});
        }
    }, [iframeId, title]);

    useEffect(() => {
        async function getIframeUrl() {
            setIsLoading(true);
            try {
                var url;
                if (filters) {
                    url = await api.post(`/flex_intel/${iframeId}`, filters).then(res => res.data);
                }else {
                    url = await api.get(`/flex_intel/${iframeId}`).then(res => res.data);
                }
                const [urlBase, urlHash] = url.split('#');
                const currentIframeStorageKey = localStorage.getItem("currentDash");
                const savedValue = localStorage.getItem(currentIframeStorageKey);

                if (savedValue) {
                    setIframeUrl(`${urlBase}${savedValue}#${urlHash}`);
                } else {
                    setIframeUrl(url);
                }
            } catch (error) {
                console.error('Error fetching iframe URL:', error);
            }
        }

        getIframeUrl();
        Mixpanel.track(title, {iframeId: iframeId, fixedFilters: filters});
    }, [iframeId, title, filters, handleMessage]);

    useEffect(() => {
        window.addEventListener("message", handleMessage, false);
        return () => {
            window.removeEventListener("message", handleMessage);
        };
    });


  const antIcon = <img src={spinner} alt="loading..." style={{"background-color":"white"}}/>
  const Loading = () => <div className="app-loading">{antIcon}</div>;
  const handleIfrmeLoad = () => setIsLoading(false);



    return (
        <>
            { isLoading ? <Loading /> : null  }
            {iframeUrl && (
                <iframe
                    id={iframeId}
                    src={iframeUrl}
                    style={{ border: 'none', position: 'relative', paddingBottom:'40px' }}
                    width="100%"
                    height="100%"
                    title={title}
                    onLoad={handleIfrmeLoad}
                />
            )}
        </>
    );
}

export default MetabaseSubtab;
