import React, {useState, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import BreadcrumbRouter from './breadcrumb-router.component';
import {Typography, Row, Col, Button} from 'antd';
import {integrationService} from './integration.service';
import {getUser} from '../user/user.slice';
import { useNavigate } from 'react-router-dom';
import { Mixpanel } from '../../mixpanel';

const {Text} = Typography;

function Cobot() {
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (params.has('code')) {
      setLoading(true);
      integrationService.cobotConnect(Object.assign({type: 'cobot'}, {code: params.get('code')}))
        .then(() => dispatch(getUser()))
        .then(() => navigate('/setup', { replace: true }))
        .catch((err) => setErrorMessage(err.response.data.message))
        .finally(() => setLoading(false));
    }
    Mixpanel.track('Integrations - Cobot');
  }, []);

  return (
    <>
      <BreadcrumbRouter />

      <h2>Cobot</h2>
      <p>If you need help connecting your Cobot account, please check <a href="https://help.coworkintel.com/articles/69670-how-to-connect-coworkintel-and-cobot" target="_blank" rel="noreferrer">our guide</a>. It's only 2 minutes!</p>

      <p><Text type="danger">{errorMessage}</Text></p>

      <Row>
        <Col lg={6}>
          <Button type="primary" href="https://www.cobot.me/oauth/authorize?response_type=code&client_id=b042a693f742a1585a95e84c5e0dee5c&redirect_uri=https://app.coworkintel.com/start/cobot&scope=read" loading={isLoading}>Connect</Button>
        </Col>
      </Row>
      <p><small>Learn about how Coworkintel, Inc. will handle your data by reviewing  our <a href="https://coworkintel.com/terms.html" target="_blank" rel="noreferrer">terms of service</a>, <a href="https://coworkintel.com/privacy-policy.html" target="_blank" rel="noreferrer">privacy policies</a> and <a href="https://coworkintel.com/policy.html" target="_blank" rel="noreferrer">competitive set policy</a>. You can always see or remove access in your Cobot Account.</small></p>
    </>
  );
}

export default Cobot;
