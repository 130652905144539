
export const brandPurpleDark = "#170041"

export const brandPurple = "#4f1597"

export const brandPink = "#FF1FD3"

export const disabledGray = "#C8C8C8"

export const closedPoints = "#CBC3E3"

export const disclaimerText = "#707070"

export const tooltipBackground = "#475358"

export const menuItemNotSelectedText = "#F9F9F9"

export const white = "#FFFFFF"

export const whiteBackground = "#F9F9F9"

export const grayBackground = "#EBEBEB"

export const brandPalette = [
    '#170041', '#2A075E', '#4F1597', '#7226D1', '#59078C', '#6E0F98', '#9316CA', '#9F0EAE',
    '#B710A2', '#FF1FD3', '#E656CA', '#F274D9', '#FFAAEE', '#FFCDF5',
];

export const flexArbitragePalette = [
    '#FFCDF5', '#F274D9', '#FF1FD3', '#9F0EAE', '#6E0F98', '#4F1597', '#170041'
];

export const demorgaphicPallette = [
    '#FFCDF5', '#FFAAEE', '#F274D9', '#FF1FD3', '#B710A2', '#9F0EAE', '#6E0F98', '#4F1597', '#170041'
];

export const createExpandedPalette = (basePalette, times) => {
    let expandedPalette = [];
    for (let i = 0; i < times; i++) {
        expandedPalette = expandedPalette.concat(basePalette);
    }
    return expandedPalette;
};

export const hexToRGB = (hex, alpha = 255) => {
    let cleanHex = hex.startsWith('#') ? hex.slice(1) : hex;

    let r = parseInt(cleanHex.substring(0,2), 16);
    let g = parseInt(cleanHex.substring(2,4), 16);
    let b = parseInt(cleanHex.substring(4,6), 16);

    return [r, g, b, alpha];
  };
