import React, {useEffect} from 'react';
import { Outlet } from 'react-router-dom';
import {Card, Layout, Steps} from 'antd';
import {LinkOutlined, SlidersOutlined, CheckOutlined} from '@ant-design/icons';
import { Mixpanel } from '../../mixpanel';
const {Content} = Layout;
const {Step} = Steps;

function Start() {
  useEffect(() => {
    Mixpanel.track('Start - Select PMS');
  });

  return (
    <>
      <Content style={{padding: '24px'}}>
        <Card>
            <Steps>
                <Step status="process" title="Start" icon={<LinkOutlined />} />
                <Step status="wait" title="Setup" icon={<SlidersOutlined />} />
                <Step status="wait" title="Done" icon={<CheckOutlined />} />
            </Steps>

            <h2>Welcome!</h2>

            <h3>Select your PMS to connect. If you don't use one of these systems, please contact us <a href='https://www.coworkintel.com/signup/'>here</a>.</h3>

            <div class="accountStart">
                <Outlet/>
            </div>
        </Card>
      </Content>
    </>
  );
}

export default Start;
