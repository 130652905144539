import { Typography } from '@mui/material';
import { disclaimerText } from '../../../../utils/colors';


export function MinimumOperatorsDisclaimer() {

    return (
        <Typography variant="caption" display="block" gutterBottom sx={{
            marginTop: '10px',
            color: disclaimerText
        }}>
            Click on Filter by Brands and select at least four operators
        </Typography>
    );
}

export default MinimumOperatorsDisclaimer;
