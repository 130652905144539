import React, {useState, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import BreadcrumbRouter from './breadcrumb-router.component';
import {Typography, Row, Col, Button} from 'antd';
import {integrationService} from './integration.service';
import {getUser} from '../user/user.slice';
import { useNavigate } from 'react-router-dom';
import { Mixpanel } from '../../mixpanel';

const {Text} = Typography;

function Spacebring() {
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();


  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (params.has('code')) {
      setLoading(true);
      integrationService.andcardsConnect(Object.assign({type: 'andcards'}, {code: params.get('code')}))
        .then(() => dispatch(getUser()))
        .then(() => navigate('/setup', { replace: true }))
        .catch((err) => setErrorMessage(err.response.data.message))
        .finally(() => setLoading(false));
    }
    Mixpanel.track('Integrations - Andcards');
  }, []);

  return (
    <>
      <BreadcrumbRouter />

      <h2>Spacebring</h2>
      <p>If you need help connecting your Spacebring account, please check <a href="https://help.coworkintel.com/articles/100278-how-to-connect-coworkintel-and-andcards" target="_blank" rel="noreferrer">our guide</a>. It's only 2 minutes!</p>

      <p><Text type="danger">{errorMessage}</Text></p>

      <Row>
        <Col lg={6}>
          <Button type="primary" href="https://api.andcards.com/oauth2/authorize?response_type=code&client_id=nWkTlIrxe3WPJKAvwXzx&redirect_uri=https://app.coworkintel.com/start/andcards&scope=bills.readonly+companies.readonly+desks.readonly+deskBookings.readonly+events.readonly+membershipRequests.readonly+memberships.readonly+organizations.readonly+rooms.readonly+roomBookings.readonly+stream.readonly+tickets.readonly+transactions.readonly" loading={isLoading}>Connect</Button>
        </Col>
      </Row>
      <p><small>Learn about how Coworkintel, Inc. will handle your data by reviewing  our <a href="https://www.coworkintel.com/terms-of-service/" target="_blank" rel="noreferrer">terms of service</a>, <a href="https://www.coworkintel.com/privacy-policy/" target="_blank" rel="noreferrer">privacy policies</a> and <a href="https://www.coworkintel.com/comparable-set-policy/" target="_blank" rel="noreferrer">competitive set policy</a>. You can always see or remove access in your Spacebring Account.</small></p>
    </>
  );
}

export default Spacebring;
