import React, { useRef, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Layout, Button, Table, message, Tag, Modal, Space, Input, Tooltip } from 'antd';
import { SearchOutlined, EditOutlined, DeleteOutlined, ExclamationCircleFilled, StarFilled, CopyOutlined, EyeFilled } from '@ant-design/icons';
import { userService } from '../user/user.service';
import { adminService } from '../admin/admin.service';
import UserModal from './user-modal.component';
import UserTemplateModal from './user-template-modal.component';
import { setTemporalTemplate } from '../user/user.slice';

const { Content } = Layout;
const { confirm } = Modal;



const calculatePageSize = () => {
    const rowHeight = 55;
    const headerHeight = 100;
    const availableHeight = window.innerHeight - headerHeight;
    return Math.floor(availableHeight / rowHeight);
};

function Users() {
    const dispatch = useDispatch();
    const searchInput = useRef(null);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isTemplateModalVisible, setIsTemplateModalVisible] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [userTemplate, setUserTemplate] = useState(null);
    const [isCreatingTemplate, setIsCreatingTemplate] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [loading, setLoading] = useState(false);
    const [dataSource, setDataSource] = useState([]);
    const [pageSize, setPageSize] = useState(calculatePageSize());
    const [visibleUsersCount, setVisibleUsersCount] = useState(0);

    const handleSearch = (confirm) => {
        confirm();
    };

    const handleReset = (clearFilters, confirm) => {
        clearFilters();
        confirm();
    };

    useEffect(() => {
        fetchUsers();
    }, []);

    useEffect(() => {
        const handleResize = () => {
            setPageSize(calculatePageSize());
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const fetchUsers = async () => {
        setLoading(true);
        const users = await userService.getUsers();
        const source = users
            .sort((a, b) => {
                if (!a.created_at) return 1;
                if (!b.created_at) return -1;
                return new Date(b.created_at) - new Date(a.created_at);
            })
            .map((user, index) => ({
                key: index,
                name: user.name,
                email: user.email,
                id: user._id,
                status: user.status,
                created_at: user.created_at,
                template: user.template,
                role: user.role,
                integrations: user.integration_objects,
            }));

        setDataSource(source);
        setVisibleUsersCount(source.length);
        setLoading(false);
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(confirm)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(confirm)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters, confirm)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1677ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) => record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase() ?? ''),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) => text
    });

    const warningModal = (user) => {
        confirm({
            title: 'Warning',
            content: 'Are you sure that you want to permanently delete this user. This action is irreversible',
            icon: <ExclamationCircleFilled />,
            onOk() { handleDelete(user.id) },
            onCancel() { }
        });
    };

    const handleDelete = async (userId) => {
        try {
            await adminService.deleteUser(userId);
            message.success('User deleted successfully');
            fetchUsers();
        } catch (error) {
            message.error('Failed to delete user');
        }
    };

    const showUserModal = (user) => {
        setSelectedUser(user);
        setIsModalVisible(true);
    };

    const closeUserModal = () => {
        setIsModalVisible(false);
        setIsEditing(false);
    };

    const copyTemplate = (user) => {
        navigator.clipboard.writeText(JSON.stringify({template: user.template.template}, null, 2));
        message.success('Template copied to clipboard');
    };

    const previsualizeTemplate = (user) => {
        dispatch(setTemporalTemplate(user.template, user.email));
        message.success('Temporarly set template to current user');
    };

    const showTemplateModal = () => {
        setUserTemplate(selectedUser.template.template);
        setIsTemplateModalVisible(true);
    };

    const closeTemplateModal = () => {
        setIsTemplateModalVisible(false);
        setIsCreatingTemplate(false);
    };

    const createTemplate = () => {
        setUserTemplate({});
        setIsCreatingTemplate(true);
        setIsTemplateModalVisible(true);
    };

    const handleSaveTemplate = async (template) => {
        try {
            await adminService.updateUserTemplate(selectedUser.id, template);
            message.success('Template updated successfully');
            closeUserModal();
            fetchUsers();
        } catch (error) {
            message.error('Failed to update template');
        }
        setIsCreatingTemplate(false);
    };

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            ...getColumnSearchProps('name'),
            render: (text, record) => (
                <span>
                    {record.role === 'admin' && <StarFilled style={{ color: 'gold', marginRight: 8 }} />}
                    {text}
                </span>
            ),
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            ...getColumnSearchProps('email'),
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            filters: [
                {
                    text: 'Start',
                    value: 'START',
                },
                {
                    text: 'Setup',
                    value: 'SETUP',
                },
                {
                    text: 'Active',
                    value: 'ACTIVE',
                },
                {
                    text: 'Disabled',
                    value: 'DISABLED',
                },
                {
                    text: 'Others',
                    value: 'OTHERS',
                },
            ],
            onFilter: (value, record) => {
                if (value === 'OTHERS') {
                    return !['START', 'SETUP', 'ACTIVE', 'DISABLED'].includes(record.status);
                }
                return record.status === value;
            },
            render: (_, { status }) => (
                <Tag color={status === 'ACTIVE' ? 'green' : status === 'START' ? 'geekblue' : status === 'SETUP' ? 'yellow' : status === 'DISABLED' ? 'volcano' : 'gray'} key={status}>
                    {status !== undefined ? status.toUpperCase() : 'NO VALID STATUS'}
                </Tag>
            ),
        },
        {
            title: 'Actions',
            dataIndex: '',
            key: 'x',
            align: 'right',
            render: (value) => {
                return <>
                    { value.template !== undefined ? <Tooltip title="User preview with my account"><Button type="text" onClick={() => previsualizeTemplate(value)} icon={<EyeFilled />} style={{ fontSize: '18px', color: '#000' }} /></Tooltip> : null }
                    { value.template !== undefined ? <Tooltip title="Copy user template"><Button type="text" onClick={() => copyTemplate(value)} icon={<CopyOutlined />} style={{ fontSize: '18px', color: '#000' }} /></Tooltip> : null }
                    <Button type="text" onClick={() => showUserModal(value)} icon={<EditOutlined />} style={{ fontSize: '18px', color: '#000' }} />
                    <Button type="text" onClick={() => warningModal(value)} icon={<DeleteOutlined />} style={{ fontSize: '18px', color: '#000' }} />
                </>;
            },
        }
    ];


    const handleTableChange = (pagination, filters, sorter, extra) => {
        setVisibleUsersCount(extra.currentDataSource.length);
    };

    return (
        <Layout style={{ height: '100%', backgroundColor: '#EBEBEB' }}>
            <Content style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                <h2>Users ({visibleUsersCount})</h2>
                <div style={{ flex: 1 }}>
                    <Table
                        style={{ height: '100%' }}
                        loading={loading}
                        dataSource={dataSource}
                        columns={columns}
                        pagination={{ pageSize: pageSize, showSizeChanger: false }}
                        scroll={{ y: 'calc(100vh - 320px)' }}
                        onChange={handleTableChange}
                    />
                </div>
                <UserModal
                    isVisible={isModalVisible}
                    onClose={closeUserModal}
                    user={selectedUser}
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                    fetchUsers={fetchUsers}
                    showTemplateModal={showTemplateModal}
                    createTemplate={createTemplate}
                />
                <UserTemplateModal
                    isVisible={isTemplateModalVisible}
                    onClose={closeTemplateModal}
                    template={{template: userTemplate}}
                    onSave={handleSaveTemplate}
                    isCreating={isCreatingTemplate}
                />
            </Content>
        </Layout>
    );
}

export default Users;
