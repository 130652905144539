import React from 'react';
import {Row, Col, Card, Tag} from 'antd';
import {ArrowRightOutlined, UploadOutlined} from '@ant-design/icons';
import {useNavigate, useLocation} from 'react-router-dom';
import officerndLogo from './officernd-logo.png';
import nexudusLogo from './nexudus-logo.png';
import cobotLogo from './cobot-logo.png';
import manualLogo from './manual-logo.png';
import pickspaceLogo from './pickspace-logo.png';
import spacebringLogo from './spacebring-logo.png';


function IntegrationsOverview({integrations}) {
    const navigate = useNavigate();
    const location = useLocation();

    const bodyStyle = {
      height: '200px',
      textAlign: 'center',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'row'
    };

    const bodyStyle2 = {
      height: '200px',
      textAlign: 'center',
      display: 'block',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'row'
    };

const isActiveIntegration = (type) => integrations.find((integration) => integration.type === type);

function getTag(type) {
    const isActive = isActiveIntegration(type);
    const color = isActive ? 'green' : 'red';
    const text = isActive ? 'Active' : 'Inactive';
    return <Tag color={color}>{text}</Tag>;
  }

  function getActions(type) {
    const isActive = isActiveIntegration(type);

    if (isActive) {
        if (type === 'manual') {
            return [<UploadOutlined key="upload" onClick={() => navigate(`${location.pathname}/${type}`)} />];
        }
        /*else {
            return [<SettingOutlined key="setting" />, <EditOutlined key="edit" />];
        }*/
    } else {
        return [<ArrowRightOutlined key="add" onClick={() => navigate(`${location.pathname}/${type}`)} />];
    }
  }


  return (
    <>
        <Row gutter={[24, 24]}>
        <Col lg={6} md={12} xs={24}>
        <Card
            extra={getTag('officernd')}
            bodyStyle={bodyStyle}
            actions={getActions('officernd')}
        >
            <img alt="officernd" src={officerndLogo} style={{maxWidth: '100%', maxHeight: '100%'}} />
        </Card>
        </Col>
        <Col lg={6} md={12} xs={24}>
        <Card
            extra={getTag('nexudus')}
            bodyStyle={bodyStyle}
            actions={getActions('nexudus')}
        >
            <img alt="nexudus" src={nexudusLogo} style={{maxWidth: '100%', maxHeight: '100%'}} />
        </Card>
        </Col>
        <Col lg={6} md={12} xs={24}>
        <Card
            extra={getTag('cobot')}
            bodyStyle={bodyStyle}
            actions={getActions('cobot')}
        >
            <img alt="cobot" src={cobotLogo} style={{maxWidth: '100%', maxHeight: '100%'}} />
        </Card>
        </Col>
        <Col lg={6} md={12} xs={24}>
        <Card
            extra={getTag('pickspace')}
            bodyStyle={bodyStyle}
            actions={getActions('pickspace')}
        >
            <img alt="pickspace" src={pickspaceLogo} style={{maxWidth: '100%', maxHeight: '100%'}} />
        </Card>
        </Col>
        </Row>
        <Row gutter={[24, 24]}>
            <Col lg={6} md={12} xs={24}>
            <Card
                extra={getTag('spacebring')}
                bodyStyle={bodyStyle}
                actions={getActions('spacebring')}
            >
                <img alt="spacebring" src={spacebringLogo} style={{maxWidth: '100%', maxHeight: '100%'}} />
            </Card>
            </Col>
            <Col lg={6} md={12} xs={24}>
            <Card
                extra={getTag('manual')}
                bodyStyle={bodyStyle2}
                actions={getActions('manual')}
            >
                <img alt="manual" src={manualLogo} style={{maxWidth: '100%', maxHeight: '80%'}} />
                <h2>Manual</h2>
            </Card>
            </Col>
        </Row>
    </>
  );
}

export default IntegrationsOverview;
