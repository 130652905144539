import { useSelector, useDispatch } from 'react-redux';
import { CartoLayer } from '@deck.gl/carto';
import { selectSourceById, updateLayer } from '@carto/react-redux';
import { useCartoLayerProps } from '@carto/react-api';
import { LEGEND_TYPES } from '@carto/react-ui';
import { hexToRGB, demorgaphicPallette } from '../../../../utils/colors';
import htmlForFeature, { FORMATTER_TYPES } from '../../../../utils/htmlForFeature';

export const MEDIAN_EARNINGS_LAYER_ID = 'medianEarningsLayer';

export const CATEGORY_COLORS = {
    '< 15K $' : hexToRGB(demorgaphicPallette[0], 204),
    '15K $ - 50K $' : hexToRGB(demorgaphicPallette[1], 204),
    '50K $ - 80K $' : hexToRGB(demorgaphicPallette[2], 204),
    '80K $ - 110K $' : hexToRGB(demorgaphicPallette[3], 204),
    '110K $ - 150K $' : hexToRGB(demorgaphicPallette[4], 204),
    '> 150K $'  : hexToRGB(demorgaphicPallette[8], 204),
};

const DATA = Object.entries(CATEGORY_COLORS).map((elem) => {
    return { color: elem[1], label: elem[0] };
});

export default function MedianEarningsLayer() {
    const dispatch = useDispatch();
    const { medianEarningsLayer } = useSelector((state) => state.carto.layers);
    const source = useSelector((state) => selectSourceById(state, medianEarningsLayer?.source));
    const demographics = useSelector((state) => state.demographics);
    const cartoLayerProps = useCartoLayerProps({ source });

    const layerConfig = {
        title: 'Median Earnings per Tract',
        visible: demographics.medianEarningsLayerVisible,
        switchable: true,
        legend: {
            collapsible: true,
            collapsed: !demographics.medianEarningsLayerVisible,
            type: LEGEND_TYPES.CATEGORY,
            labels: DATA.map((data) => data.label),
            colors: DATA.map((data) => data.color),
        },
    };

    if (medianEarningsLayer && source) {
        return new CartoLayer({
            ...cartoLayerProps,
            id: MEDIAN_EARNINGS_LAYER_ID,
            geoColumn: 'geom',
            lineWidthMinPixels: 1,
            visible: medianEarningsLayer.visible,
            getFillColor: (object) => {
                const value = Math.floor(object.properties.earning_per_tract || 0).toFixed(0);
                return value < 15000 && value >= 0 ? hexToRGB(demorgaphicPallette[0], 204) :
                    value < 50000 && value >= 15000 ? hexToRGB(demorgaphicPallette[1], 204) :
                    value < 80000 && value >= 50000 ? hexToRGB(demorgaphicPallette[2], 204) :
                    value < 110000 && value >= 80000 ? hexToRGB(demorgaphicPallette[3], 204) :
                    value < 150000 && value >= 110000 ? hexToRGB(demorgaphicPallette[4], 204) :
                    value >= 150000 ? hexToRGB(demorgaphicPallette[8], 204) : hexToRGB(demorgaphicPallette[8], 204);
            },
            getLineColor: (object) => {
                const value = Math.floor(object.properties.earning_per_tract || 0).toFixed(0);
                return value < 15000 && value >= 0 ? hexToRGB(demorgaphicPallette[0], 204) :
                    value < 50000 && value >= 15000 ? hexToRGB(demorgaphicPallette[1], 204) :
                    value < 80000 && value >= 50000 ? hexToRGB(demorgaphicPallette[2], 204) :
                    value < 110000 && value >= 80000 ? hexToRGB(demorgaphicPallette[3], 204) :
                    value < 150000 && value >= 110000 ? hexToRGB(demorgaphicPallette[4], 204) :
                    value >= 150000 ? hexToRGB(demorgaphicPallette[8], 204) : hexToRGB(demorgaphicPallette[8], 204);
            },
            pickable: true,
            onDataLoad: (data) => {
                dispatch(
                    updateLayer({ id: MEDIAN_EARNINGS_LAYER_ID, layerAttributes: { ...layerConfig } })
                );
                cartoLayerProps.onDataLoad && cartoLayerProps.onDataLoad(data);
            },
            onDataError: (error) => { console.log(error) },
            onHover: (info) => {
                if (info?.object) {
                    info.object = {
                        html: htmlForFeature({
                            feature: info.object,
                            formatters: [
                                {
                                    type: FORMATTER_TYPES.NUMBER,
                                    column: 'earning_per_tract',
                                },
                            ],
                            includeColumns: [
                                {'originalName':'earning_per_tract', 'alias' : 'Earning per Tract'},
                            ],
                         }),
                        style: {},
                    };
                }
            },
        });
    }
}
