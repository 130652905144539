import React, { useMemo } from 'react';
import { Menu, Dropdown, Space, Typography } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { NavLink, useLocation } from 'react-router-dom';
import { getSubtabType } from '../utils/utils';
import { brandPurple, brandPurpleDark, whiteBackground } from '../utils/colors';
import './submenu-bar.scss';

const SubmenuBar = ({ currentTab, subSubtabTitle }) => {

    const location = useLocation();

    const subtabs = useMemo(() => currentTab.subtabs.map((subtab, index) => (
        <Menu.Item key={subtab.title} disabled={false}>
            { generateSubMenuItem(subtab, currentTab, index, subSubtabTitle) }
        </Menu.Item>
    )), [currentTab, subSubtabTitle]);


    return (
        <Menu mode="horizontal"  style={{ justifyContent: "center", background: whiteBackground }}  selectedKeys={location.pathname} >
            {subtabs}
        </Menu>
    );
};

export default React.memo(SubmenuBar);

function generateSubMenuItem(subtab, currentTab, index, subSubtabTitle) {
    return <div style={{ display: 'flex', alignItems: 'center', position: 'relative', width: '100%', paddingTop: '5px', paddingBottom: '10px'}}>
        {index !== 0 && (
            <div style={{
                height: '70%',
                width: '1px',
                backgroundColor: brandPurple,
                position: 'absolute',
                left: '-20px',
                top: '15%'
            }}></div>
        )}
            <div style={{
                paddingLeft: '50px',
                paddingRight: '50px',
                width: '100%',
                display: 'flex',
                alignItems: 'center'
            }}>
                {generateSubtabItem(subtab, currentTab, subSubtabTitle)}
        </div>
</div>;
}

function generateSubtabItem(subtab, currentTab, subSubtabTitle) {
    if (!subtab.subtabs || subtab.subtabs.length === 0) {
        return (
            <NavLink to={`${getSubtabType(subtab)}/${encodeURIComponent(subtab.title)}`}
            state={{ currentTab: currentTab, subtab: subtab, subSubtabTitle: undefined }}
            style={({ isActive }) => {
                return {
                    fontWeight: "bold",
                    color: isActive ? brandPurple : brandPurpleDark,
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    display: 'block',
                    textDecoration: isActive ? "underline" : "",
                    textDecorationColor: isActive ? brandPurple : "",
                    textDecorationThickness: isActive ? "3px" : "",
                    textUnderlineOffset: isActive ? "6px" : "",
                };
            } }>
            {subtab.title}
        </NavLink>
        );
    }

    const items = subtab.subtabs.map((subtabSubtab) => (
        {
            key: subtabSubtab.title,
            label: (
                <NavLink to={`${getSubtabType(subtab)}/${encodeURIComponent(`${subtab.title} - ${subtabSubtab.title}`)}`}
                    state={{ currentTab: currentTab, subtab: subtabSubtab, subSubtabTitle: subtabSubtab.title}}
                    style={({ isActive }) => {
                        return {
                            fontWeight: "bold" ,
                            color: isActive ? brandPurple : brandPurpleDark,
                            display: 'block',
                            textDecoration: isActive ? "underline" : "",
                            textDecorationColor: isActive ? brandPurple : "",
                            textDecorationThickness: isActive ? "3px" : "",
                            textUnderlineOffset: isActive ? "6px" : "",
                        };
                    } }>
                    {subtabSubtab.title}
                </NavLink>
            ),
          }
    ));

    const titleExtra = subSubtabTitle ? ` - ${subSubtabTitle}` : '';
    const isSelected = titleExtra !== ''
    const style = {
            fontWeight: "bold",
            color: isSelected ? brandPurple : brandPurpleDark,
            display: 'block',
            textDecoration: isSelected ? "underline" : "",
            textDecorationColor: isSelected ? brandPurple : "",
            textDecorationThickness: isSelected ? "3px" : "",
            textUnderlineOffset: isSelected ? "6px" : "",

    };

    return  <Dropdown menu={{ items }} trigger={['click']} >
        <a onClick={(e) => e.preventDefault()}>
            <Space>
                <Typography style={style} >{subtab.title} {titleExtra} </Typography>
                <DownOutlined />
            </Space>
        </a>
    </Dropdown>

}

