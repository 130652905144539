import { MAP_TYPES } from '@deck.gl/carto';
import { generateWhereClauses, generateQuery } from '../query-utils';

export const FLEX_ARBITRAGE_SOURCE_ID = 'flexArbitrageSource';

export const FLEX_ARBITRAGE_CARTO_ORIGIN = 'carto-dw-ac-l5aq60v6.shared_us.rent_premium_to_meta_us_40'

export default function createFlexArbitrageSource(currentSubtab, flexArbitrageRatio = [0, 10]) {
    const baseQuery = `SELECT * FROM ${FLEX_ARBITRAGE_CARTO_ORIGIN}`;

    const whereClauses = generateWhereClauses(
        currentSubtab.filters ?? [],
        currentSubtab.not_filters ?? [],
        [`rent_preium_sqf BETWEEN ${flexArbitrageRatio[0]} AND ${flexArbitrageRatio[1]}`]
    );

    const finalQuery = generateQuery(whereClauses, baseQuery);

    return {
        id: FLEX_ARBITRAGE_SOURCE_ID,
        type: MAP_TYPES.QUERY,
        connection: 'carto_dw',
        data: finalQuery,
        isDroppingFeatures: false
    };
}
