
export async function fetchSQLData(params) {
    const baseUrl = 'https://gcp-europe-west1.api.carto.com/v3/sql/carto_dw/query';

    const url = `${baseUrl}?${params.toString()}`;
    const response = await fetch(url, {
        method: 'GET',
        headers: {
            'Accept': '*/*',
            'Accept-Encoding': 'gzip, deflate, br',
            'Accept-Language': 'es-ES,es;q=0.9',
            'Authorization': 'Bearer eyJhbGciOiJIUzI1NiJ9.eyJhIjoiYWNfbDVhcTYwdjYiLCJqdGkiOiI5NmQ0ZjgzMiJ9.RowRg7wJOJB1dH6NvOdpsJzfxV9eeT-OW4rvLj51APg',
        }
    });
    const data = await response.json();
    return data;
};

export function generateParamsForQuery(query) {
    return new URLSearchParams({
        format: 'tilejson',
        q: query,
        cache: '1690973704739',
        v: '3.1',
        client: 'carto-for-react'
    });
}
