import api from '../../api';

export const userService = {
  signup,
  login,
  loginDemo,
  logout,
  forgotPassword,
  me,
  getById,
  update,
  getUsers,
  getToken,
  template
};

function signup(data) {
  return api.post('/users', data)
    .then((res) => {
      login(data)
    })
    .catch((err) => {
      console.log('ERRR signup', err);
      throw err;
    });
}

function login(data) {
  return api
    .post('/users/login', data)
    .then((res) => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      localStorage.setItem('token', res.data.token);
      return res.data.token;
    })
    .catch((err) => {
      console.log('ERRR login', err);
      throw err;
    });
}

function loginDemo(data) {
  return api
    .post('/users/login', data)
    .then((res) => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      localStorage.setItem('demoToken', res.data.token);
      return res.data.token;
    })
    .catch((err) => {
      console.log('ERRR login', err);
      throw err;
    });
}

function logout() {
  localStorage.clear();
}

function forgotPassword(data) {
  return api.post('/users/forgot-password', data)
    .then((res) => res.data)
    .catch((err) => {
      console.log('ERRR forgot password', err);
      throw err;
    });
}

function me() {
  return api
    .get('/users/me')
    .then(({data}) => data);
}

function getById(id) {
  return api.get(`/users/${id}`);
}

function update(user) {
  return api.put(`/users/${user.id}`, user);
}

function getToken() {
  return localStorage.getItem('token');
}

function getUsers() {
  return api
    .get('/users')
    .then(({data}) => data);
}

function template() {
    return api
      .get('/users/template')
      .then(({data}) => data)
      .catch((err) => {
        console.log('ERROR on getting user template', err) // no need to handle the error, we just don't show any tab
    });
  }
