import { MAP_TYPES } from '@deck.gl/carto';
import { generateWhereClauses, generateQuery } from '../query-utils';
import { FLEX_PRICE_CARTO_ORIGIN } from './flex-price-source'

export const FLEX_PRICE_NO_DEALS_SOURCE_ID = 'flexPriceNoDealsSource';

export default function createFlexPriceNoDealsSource(currentSubtab) {
    const baseQuery = `SELECT * FROM ${FLEX_PRICE_CARTO_ORIGIN}`;

    const whereClauses = generateWhereClauses(
        currentSubtab.filters ?? [],
        currentSubtab.not_filters ?? [],
        [`deal_id IS NULL`]
    );

    const finalQuery = generateQuery(whereClauses, baseQuery);

    return {
        id: FLEX_PRICE_NO_DEALS_SOURCE_ID,
        type: MAP_TYPES.QUERY,
        connection: 'carto_dw',
        data: finalQuery,
        isDroppingFeatures: false
    };
}
