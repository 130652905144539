import { useSelector, useDispatch } from 'react-redux';
import { CartoLayer } from '@deck.gl/carto';
import { selectSourceById, updateLayer } from '@carto/react-redux';
import { useCartoLayerProps } from '@carto/react-api';
import { LEGEND_TYPES } from '@carto/react-ui';
import { hexToRGB, demorgaphicPallette } from '../../../../utils/colors';
import htmlForFeature from '../../../../utils/htmlForFeature';

export const COMMUTING_LAYER_ID = 'commutingLayer';

export const CATEGORY_COLORS = {
    '0 - 71' : hexToRGB(demorgaphicPallette[0], 204),
    '71 - 3000' : hexToRGB(demorgaphicPallette[1], 204),
    '3000 - 6000' : hexToRGB(demorgaphicPallette[2], 204),
    '6000 - 20000' : hexToRGB(demorgaphicPallette[3], 204),
    '20000 - 150000' : hexToRGB(demorgaphicPallette[4], 204),
    '150000 - 250000'  : hexToRGB(demorgaphicPallette[5], 204),
    '250000 - 350000'  : hexToRGB(demorgaphicPallette[6], 204),
    '350000 - 500000'  : hexToRGB(demorgaphicPallette[7], 204),
    '> 500000'  : hexToRGB(demorgaphicPallette[8], 204),
};

const DATA = Object.entries(CATEGORY_COLORS).map((elem) => {
    return { color: elem[1], label: elem[0] };
});


export default function CommutingLayer() {
    const dispatch = useDispatch();
    const { commutingLayer } = useSelector((state) => state.carto.layers);
    const source = useSelector((state) => selectSourceById(state, commutingLayer?.source));
    const demographics = useSelector((state) => state.demographics);
    const cartoLayerProps = useCartoLayerProps({ source });

    const layerConfig = {
        title: 'Commuting Flow',
        visible: demographics.commutingLayerVisible,
        switchable: true,
        legend: {
            collapsible: true,
            collapsed: !demographics.commutingLayerVisible,
            type: LEGEND_TYPES.CATEGORY,
            labels: DATA.map((data) => data.label),
            colors: DATA.map((data) => data.color),
        },
    };


    if (commutingLayer && source) {
        return new CartoLayer({
            ...cartoLayerProps,
            id: COMMUTING_LAYER_ID,
            geoColumn: 'geom',
            lineWidthMinPixels: 1,
            visible: commutingLayer.visible,
            getFillColor: (object) => {
                const value = Math.floor(object.properties.total_flow || 0).toFixed(0);
                return value < 71 && value >= 0 ? hexToRGB(demorgaphicPallette[0], 204) :
                    value < 3000 && value >= 71 ? hexToRGB(demorgaphicPallette[1], 204) :
                    value < 6000 && value >= 3000 ? hexToRGB(demorgaphicPallette[2], 204) :
                    value < 20000 && value >= 6000 ? hexToRGB(demorgaphicPallette[3], 204) :
                    value < 150000 && value >= 20000 ? hexToRGB(demorgaphicPallette[4], 204) :
                    value < 250000 && value >= 150000 ? hexToRGB(demorgaphicPallette[5], 204) :
                    value < 350000 && value >= 250000 ? hexToRGB(demorgaphicPallette[6], 204) :
                    value < 500000 && value >= 350000 ? hexToRGB(demorgaphicPallette[7], 204) :
                    value >= 500000 ? hexToRGB(demorgaphicPallette[8], 204) : hexToRGB(demorgaphicPallette[8], 204);
            },
            getLineColor: (object) => {
                const value = Math.floor(object.properties.total_flow || 0).toFixed(0);
                return value < 71 && value >= 0 ? hexToRGB(demorgaphicPallette[0], 204) :
                    value < 3000 && value >= 71 ? hexToRGB(demorgaphicPallette[1], 204) :
                    value < 6000 && value >= 3000 ? hexToRGB(demorgaphicPallette[2], 204) :
                    value < 20000 && value >= 6000 ? hexToRGB(demorgaphicPallette[3], 204) :
                    value < 150000 && value >= 20000 ? hexToRGB(demorgaphicPallette[4], 204) :
                    value < 250000 && value >= 150000 ? hexToRGB(demorgaphicPallette[5], 204) :
                    value < 350000 && value >= 250000 ? hexToRGB(demorgaphicPallette[6], 204) :
                    value < 500000 && value >= 350000 ? hexToRGB(demorgaphicPallette[7], 204) :
                    value >= 500000 ? hexToRGB(demorgaphicPallette[8], 204) : hexToRGB(demorgaphicPallette[8], 204);
            },
            pickable: true,
            onDataLoad: (data) => {
                dispatch(
                    updateLayer({ id: COMMUTING_LAYER_ID, layerAttributes: { ...layerConfig } })
                );
                cartoLayerProps.onDataLoad && cartoLayerProps.onDataLoad(data);
            },
            onDataError: (error) => { console.log(error) },
            onHover: (info) => {
                if (info?.object) {
                    info.object = {
                        html: htmlForFeature({
                            feature: info.object,
                            includeColumns: [
                                {'originalName':'origin_county_name', 'alias' : 'County'},
                                {'originalName':'total_flow', 'alias' : 'Total Flow'},
                            ],
                         }),
                        style: {},
                    };
                }
            },
        });
    }
}
