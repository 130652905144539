import axios from 'axios';
import config from './config';
import {logout} from './modules/user/user.slice';

const api = axios.create({
  baseURL: config.API_URL,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json'
  }
});

api.getCancelToken = () => axios.CancelToken.source();

export const setupInterceptors = (store) => {
  api.interceptors.request.use((reqConfig) => {
      const token = localStorage.getItem('token');
      var demoToken = localStorage.getItem('demoToken');
      var userStatus = localStorage.getItem('userStatus');
      if (token) {
        reqConfig.headers['Authorization'] = token;
      }
      if ((userStatus === 'DEMO' || userStatus === 'ADMIN') && demoToken && reqConfig.url.includes('/integrations/')) {
        reqConfig.headers['Authorization'] = demoToken;
      }
      return reqConfig
    }, (err) => Promise.reject(err)
  );

  api.interceptors.response.use(
    (r) => r,
    (err) => {
      if (axios.isCancel(err)) {
        console.log('Request was successfully cancelled');
        return Promise.resolve();
      }
      if (err && err.response && err.response.status === 401) {
        store.dispatch(logout())
      }
      return Promise.reject(err);
    }
  );
};

export default api
