import React from 'react';
import { useLocation } from 'react-router-dom';
import { addSource } from '@carto/react-redux';
import { Grid, Stack, Box } from '@mui/material';
import {  useDispatch, useSelector } from 'react-redux';
import createFlexArbitrageSource from '../../sources/flex-arbitrage-source';
import { Col, InputNumber, Slider } from 'antd';
import { createWidgetActions }  from '../../../../utils/utils';
import { WrapperWidgetUI } from '@carto/react-ui';
import { changeRatio } from '../../store/flex-arbitrage-slice';


export function FlexArbitrageRatioSlider() {
    const location = useLocation();
    const dispatch = useDispatch();

    const { subtab } = location.state;

    const flexArbitrageRatio = useSelector((state) => state.flexArbitrage.flexArbitrageRatio);

    const onChangeMin = (value) => {
        onChange([value, flexArbitrageRatio[1]]);
    };

    const onChangeMax = (value) => {
        onChange([flexArbitrageRatio[0], value]);
    };

    const onChange = (value) => {
        dispatch(changeRatio(value));

        const mapSource = createFlexArbitrageSource(subtab, value);
        dispatch(addSource(mapSource));
    };

    return (

        <WrapperWidgetUI
            title="Flex Arbitrage Ratio Filter"
            actions={createWidgetActions('The Flex Arbitrage Ratio is the ratio of the price charged by operators to tenants and what they pay to their landlord for their lease. It is an estimate of the margin made by operator expressed as a multiple. This controller enables you to select Flex Arbitrage Ratio in a range of your choice in the area. Use the controller to focus only on the high arbitrage ratios for instance between 3x and 6x or identify overcompetitive areas (where the ratio might be lower than 3x).')}
        >
            <Box sx={{
                    width: '100%',
                    marginTop: '20px',
                    paddingTop: 0,
                }} alignItems='center' >
                <Grid container direction='column' alignItems='center' >
                    <Box sx={{ width: '100%', padding: '4px'}}>
                        <Stack direction="row" spacing={2} style={{ width: '100%' }}>
                                <Col span={2} >
                                    <InputNumber
                                        min={0}
                                        max={20}
                                        value={flexArbitrageRatio[0]}
                                        onChange={onChangeMin}
                                        style={{ width: '50px' }}
                                    />
                                </Col>
                                <Col span={12} style={{ paddingLeft: '20px' }} >
                                    <Slider
                                        range
                                        step={0.1}
                                        min={0}
                                        max={10}
                                        defaultValue={[0, 10]}
                                        onChange={onChange}
                                        value={[ flexArbitrageRatio[0] , flexArbitrageRatio[1] ]}
                                    />
                                </Col>
                                <Col span={2}>
                                    <InputNumber
                                        min={0}
                                        max={20}
                                        value={flexArbitrageRatio[1]}
                                        onChange={onChangeMax}
                                        style={{ width: '50px' }}
                                    />
                                </Col>
                        </Stack>
                    </Box>
                </Grid>
            </Box>
        </WrapperWidgetUI>
    );
}

export default FlexArbitrageRatioSlider;
