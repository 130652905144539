import React, { useEffect, useMemo, useState } from 'react';
import { Layout, message } from 'antd';
import { Link, useLocation, useNavigate, Outlet } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import logo from '../logo.svg';
import './main-dashboard.scss';
import MenuBar from './menu-bar.component';
import { whiteBackground } from '../utils/colors';
import { getUserTemplate } from '../modules/user/user.slice';

const { Header, Footer } = Layout;
const logoStyles = { float: 'left', padding: '14px 32px 14px 0', maxHeight: '100%', maxWidth: '170px' };

function MainDashboard({ children }) {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const user = useSelector(state => state.user);

    const [template, setTemplate] = useState([]);
    const [isTemplateTemporal, setIsTemplateTemporal] = useState(false);
    const [temporalEmail, setTemporalEmail] = useState(null);
    const [shouldNavigate, setShouldNavigate] = useState(false);

    useEffect(() => {
        setTemplate(user.userTemplate?.template || []);
        setIsTemplateTemporal(user.isTemporalTemplate);
        setTemporalEmail(user.temporalUserEmail);
    }, [user]);


    useEffect(() => {
        const handleErrors = (message, source, lineno, colno, error) => {
            if (message && message.includes('ResizeObserver')) {
                console.log('Error de ResizeObserver capturado y manejado.');
                return true;
            }
            return false;
        };

        window.addEventListener('error', handleErrors);

        return () => {
          window.removeEventListener('error', handleErrors);
        };
      }, []);

    useEffect(() => {
        if (location.pathname === '/' && template.length > 0) {
            navigateToFirstTab();
        }

        var userStatus = localStorage.getItem('userStatus');
        if (location.pathname === '/' && template.length === 0 && userStatus !== 'ACTIVE') {
            navigate(`/${userStatus.toLocaleLowerCase}`);
        }
    });

    useEffect(() => {
        if (shouldNavigate && template.length > 0) {
            navigateToFirstTab();
            setShouldNavigate(false);
        }
    }, [shouldNavigate, template]);

    const navigateToFirstTab = () => {
        const currentTabIframe = localStorage.getItem("currentDash");
        const firstTab = findFirstTab(currentTabIframe, template);

        navigate(`${encodeURIComponent(firstTab.tab)}`, { state: { currentTab: firstTab } });
    };

    const findFirstTab = (currentTabIframe, template) => {
        const [tab] = currentTabIframe?.split(':') || [];
        return template.find(item => item.tab === tab) || template[0];
    };

    const currentPath = useMemo(() => location.pathname, [location.pathname]);

    const footerStyle = {
        textAlign: 'end',
        color: whiteBackground,
        backgroundColor: whiteBackground,
        background: whiteBackground,
        position: 'fixed',
        left: 0,
        bottom: 0,
        height: '40px',
        zIndex: 1000,
        width: '100%',
        padding: '6px 16px 0px 4px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    };

    const temporalStyle = {
        backgroundColor: 'red',
        color: 'white',
        borderRadius: '20px',
        padding: '2px 30px',
        marginRight: 'auto',
        display: 'flex',
        alignItems: 'center'
    };

    const helpLinkStyle = {
        marginLeft: 'auto',
    };

    const buttonStyle = {
        backgroundColor: '#8B0000',
        color: 'white',
        border: 'none',
        borderRadius: '5px',
        padding: '1px 10px',
        marginLeft: '10px',
        cursor: 'pointer'
    };

    const handleRestoreTemplate = async () => {
        try {
            await dispatch(getUserTemplate());
            message.success('Template restored successfully');
            setShouldNavigate(true); // Establece el estado para navegar después de restaurar el template
        } catch (error) {
            message.error('Failed to restore template');
        }
    };

    return (
        <Layout >
            <Header>
                <Link to="/"><img style={logoStyles} src={logo} alt="coworkintel logo" /></Link>
                <MenuBar template={template} currentPath={currentPath} />
            </Header>
            <Layout >
                <Outlet />
            </Layout>
            <Footer style={footerStyle}>
                {isTemplateTemporal && (
                    <div style={temporalStyle}>
                        <span>Preview template - Currently seeing view for {temporalEmail ?? "Email not set"}</span>
                        <button style={buttonStyle} onClick={handleRestoreTemplate}>Stop user preview</button>
                    </div>
                )}
                <a href="https://scribehow.com/page/User_Guide__BwFkEP7VRUuQeqsPIXVXsA" target="_blank" rel="noopener noreferrer" style={helpLinkStyle}>
                    Help
                </a>
            </Footer>
        </Layout>
    );
}

export default MainDashboard;
