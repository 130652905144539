import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import createMarketDemandSource from '../../../sources/market-demand-source';
import createMarketDemandNoEnquirySource from '../../../sources/market-demand-no-enquiry-source';
import { MARKET_DEMAND_LAYER_ID } from '../../layers/market-demand-layer';
import { MARKET_DEMAND_NO_ENQUIRY_LAYER_ID } from '../../layers/market-demand-no-enquiry-layer';
import { useDispatch, useSelector } from 'react-redux';
import MarketDemandLineChart from '../../widgets/market-demand-line-chart.component';
import {
  addLayer,
  removeLayer,
  addSource,
  removeSource,
  setViewState,
} from '@carto/react-redux';
import { Grid } from '@mui/material';
import { WrapperWidgetUI } from '@carto/react-ui';
import { DataDisclaimer } from '../../common/data-disclaimer.component';
import { createWidgetActions }  from '../../../../../utils/utils';
import { clearLandLordIntelSelected } from '../../../store/market-share-slice';

export default function MarketDemand() {
    const dispatch = useDispatch();
    const location = useLocation();
    const viewState = useSelector((state) => state.carto.viewState);
    const { subtab } = location.state;

    dispatch(clearLandLordIntelSelected())

    useEffect(() => {
        const marketDemandSource = createMarketDemandSource(subtab);
        const marketDemandNoEnquirySource = createMarketDemandNoEnquirySource(subtab);

        dispatch( addSource(marketDemandSource));
        dispatch( addLayer({ id: MARKET_DEMAND_LAYER_ID, source: marketDemandSource.id}) );

        dispatch( addSource(marketDemandNoEnquirySource));
        dispatch( addLayer({ id: MARKET_DEMAND_NO_ENQUIRY_LAYER_ID, source: marketDemandNoEnquirySource.id}) );

        if(viewState.latitude === 0 && viewState.longitude === 0 && viewState.zoom === 0) {
            const latitude = subtab?.setup?.latitude || 0;
            const longitude = subtab?.setup?.longitude || 0;
            const zoom = subtab?.setup?.zoom || 3;
            dispatch(setViewState({ latitude: latitude, longitude: longitude, zoom: zoom, transitionDuration: 500 }));
        }

        return () => {
            dispatch(removeLayer(MARKET_DEMAND_LAYER_ID));
            dispatch(removeSource(marketDemandSource.id));
            dispatch(removeLayer(MARKET_DEMAND_NO_ENQUIRY_LAYER_ID));
            dispatch(removeSource(marketDemandNoEnquirySource.id));
        };
    }, [dispatch, subtab]);

    return (
        <Grid container direction='column' spacing={2}>
             <Grid item sx={{
                            maxWidth:'420px !important',
                            '& section' : {
                                maxWidth:'100% !important',
                                width:'100% !important',
                                '& div' : {
                                    maxWidth:'100% !important',
                                }
                            }
                        }}>
                    <WrapperWidgetUI
                        title='Monthly enquiries (sqft)*'
                        actions={createWidgetActions(`This historical line chart presents the monthly evolution of demand.This snapshot of the demand trend is based on the volume of inquiries received on Instant’s websites. The calculation uses the sum of inquired desks and an estimated average of 50 sqft per desk to provide an extrapolated level of demand expressed in square feet.`)}
                    >
                        <MarketDemandLineChart subtab={subtab}/>
                    </WrapperWidgetUI>
            </Grid>
            <Grid item>
                <DataDisclaimer />
            </Grid>
        </Grid>
    );
}
