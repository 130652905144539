var mixpanel = require('mixpanel-browser');
mixpanel.init('c2fc44d23fb246824fd8189bf4075215');

let env_check = process.env.NODE_ENV === 'production';

let actions = {
  identify: (id) => {
    if (env_check) mixpanel.identify(id);
  },
  alias: (id) => {
    if (env_check) mixpanel.alias(id);
  },
  track: (eventName) => {
    if (env_check) {
        mixpanel.track(eventName)
    }
  },
  trackProperty: (eventName, property) => {
    if (env_check) {
        mixpanel.track(eventName, property);
    }
  },
  reset: () => {
    if (env_check) {
        mixpanel.reset()
    }
  },
  people: {
    set: (user) => {
      if (env_check) mixpanel.people.set(user.email, {
        $name: user.name,
        $email: user.email,
        $created_at: (new Date()).toISOString(),
        $status: user.status
    });
    },
  },
};

export let Mixpanel = actions;
