import React from 'react';
import * as icons from '@ant-design/icons';

const DynamicIcon = ({ iconName }) => {
  const IconComponent = icons[iconName];

  return IconComponent ? React.createElement(IconComponent) : null;
};

export default DynamicIcon;
