import { MAP_TYPES } from '@deck.gl/carto';

export const MEDIAN_EARNINGS_SOURCE_ID = 'medianEarningsSource';

export const MEDIAN_EARNINGS_CARTO_ORIGIN = 'carto-dw-ac-l5aq60v6.shared_us.median_earnings_per_tract_usa'

export default function createMedianEarningsSource() {
    const baseQuery = `SELECT * FROM ${MEDIAN_EARNINGS_CARTO_ORIGIN}`;

    return {
        id: MEDIAN_EARNINGS_SOURCE_ID,
        type: MAP_TYPES.QUERY,
        connection: 'carto_dw',
        data: baseQuery,
        isDroppingFeatures: false
    };
}
