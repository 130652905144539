import { arrayToString, escapeValue } from '../../utils/utils';

export function generateQuery(whereClauses, baseQuery) {
    const finalQuery = whereClauses.length ?
        `${baseQuery} WHERE ${joinWheres(whereClauses)}` :
        `${baseQuery} WHERE 1=0`; // If there is no clauses we don't want to show any data

    return finalQuery
}

function generateClauses(filters, operator = '=') {
    return Object.entries(filters || {}).map(([key, value]) => {
        const clauses = value.map(item => {
            if (typeof item === 'string') {
                return `${key} ${operator} '${escapeValue(item)}'`;
            } else if (typeof item === 'object') {
                const subClauses = Object.entries(item).map(([subKey, subValue]) => {
                    if (Array.isArray(subValue)) {
                        return `${subKey} ${operator === '=' ? 'IN' : 'NOT IN'} ${arrayToString(subValue)}`;
                    }
                    return `${subKey} ${operator} '${escapeValue(subValue)}'`;
                }).join(' AND ');
                return `(${subClauses})`;
            }
            return null;
        }).filter(clause => clause !== null);
        if (clauses.length > 1) {
            return `(${clauses.join(' OR ')})`;
        }
        return clauses[0];
    }).filter(clause => clause !== '');
}

export function generateWhereClauses(filters, not_filters, extraWheres = []){
    const whereClauses = [];


    const whereInClauses = generateClauses(filters);
    const whereNotInClauses = generateClauses(not_filters, '!=');

    whereClauses.push(whereInClauses);
    whereClauses.push(whereNotInClauses);

    extraWheres.forEach(where => {
        whereClauses.push(where);
    });

    return whereClauses;
}

export function addWhereClause(baseQuery, whereClause) {
    const finalQuery = `${baseQuery} AND ${whereClause}`;

    return finalQuery
}

function joinWheres(whereClauses){
    const filteredClauses = whereClauses.filter(clause => clause !== null && !(Array.isArray(clause) && clause.length === 0));

    const stringClauses = filteredClauses.map(clause => Array.isArray(clause) ? clause.join(', ') : clause);

    return stringClauses.join(' AND ');
}
