import { MAP_TYPES } from '@deck.gl/carto';
import { generateWhereClauses, generateQuery } from '../query-utils';

export const LANDLORD_INTEL_SOURCE_ID = 'landlordIntel';

export const LANDLORD_INTEL_CARTO_ORIGIN = 'carto-dw-ac-l5aq60v6.shared_us.supply_landlords_info'

export default function createLandlordIntelSource(currentSubtab) {
    const baseQuery = `SELECT * FROM ${LANDLORD_INTEL_CARTO_ORIGIN}`;

    const whereClauses = generateWhereClauses(
        currentSubtab.filters ?? [],
        currentSubtab.not_filters ?? [],
        ['landlord_url IS NOT NULL']
    );

    const finalQuery = generateQuery(whereClauses, baseQuery);

    return {
        id: LANDLORD_INTEL_SOURCE_ID,
        type: MAP_TYPES.QUERY,
        connection: 'carto_dw',
        data: finalQuery,
        isDroppingFeatures: false
    };
}
