import React, {useState, useEffect} from 'react';
import {LoadingOutlined} from '@ant-design/icons';
import {Card, Layout, Steps} from 'antd';
import {LinkOutlined, SlidersOutlined, CheckOutlined} from '@ant-design/icons';
import { Mixpanel } from '../../mixpanel';

const {Content} = Layout;
const {Step} = Steps;

function DataIsImportingMessage() {
  return (
    <>
      <p><LoadingOutlined spin /> Data is importing...</p>
      <p>Please wait some minutes and refresh the page to continue with your account setup.</p>
    </>
  );
}

const STEPS = {
  stepResourceMapping: 'MAP',
  stepResourceSize: 'SIZE',
  stepResourceStatus: 'STATUS',
  stepDone: 'DONE'
};

function DataMissingMessage({integrationId}) {
  const [step] = useState(STEPS.stepResourceMapping);

  useEffect(() => {
    if (step === STEPS.stepResourceSize) {
      console.log('save step resource mapping');
    } else if (step === STEPS.stepResourceStatus) {
      console.log('save step resource sizes');
    } else if (step === STEPS.stepDone) {
      console.log('save step resource status');
    }
  }, [step]);

  return (
    <>
    <h3>Processing the data...</h3>
    <p>We are setting up your account. Your dashboard will be ready in less than 24h.</p>
    </>
  );
}

function Setup({profile}) {
  const [integration] = profile.integrations;
  const isInProgress = integration.data_status === 'IN_PROGRESS';

  useEffect(() => {
    Mixpanel.track('Setup - Waiting for data setup');
  });

  return (
    <>
      <Content style={{padding: '24px'}}>
        <Card>
        <Steps>
          <Step status="done" title="Start" icon={<LinkOutlined />} />
          <Step status="process" title="Setup" icon={<SlidersOutlined />} />
          <Step status="wait" title="Done" icon={<CheckOutlined />} />
        </Steps>

        {isInProgress ? <DataIsImportingMessage/> : <DataMissingMessage integrationId={integration._id} />}

        </Card>
      </Content>
    </>
  );
}

export default Setup;
