import { MAP_TYPES } from '@deck.gl/carto';
import { generateWhereClauses, generateQuery } from '../query-utils';
import { MARKET_DEMMAND_CARTO_ORIGIN } from './market-demand-source'

export const MARKET_DEMAND_NO_ENQUIRY_SOURCE_ID = 'marketDemandNoEnquirySource';

export default function createMarketDemandNoEnquirySource(currentSubtab) {
    const baseQuery = ` SELECT * FROM ${MARKET_DEMMAND_CARTO_ORIGIN}`;

    const whereClauses = generateWhereClauses(
        currentSubtab.filters ?? [],
        currentSubtab.not_filters ?? [],
        [`enquiry_id IS NULL`]
    );

    const finalQuery = generateQuery(whereClauses, baseQuery);

    return {
        id: MARKET_DEMAND_NO_ENQUIRY_SOURCE_ID,
        type: MAP_TYPES.QUERY,
        connection: 'carto_dw',
        data: finalQuery,
        isDroppingFeatures: false
    };
}
