import React, { useEffect, useState } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {UserOutlined, LinkOutlined, LogoutOutlined} from '@ant-design/icons';
import {Layout, Menu} from 'antd';
import {BarChartOutlined} from '@ant-design/icons';
import {Link, useLocation, Outlet} from 'react-router-dom';
import {useNavigate} from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";
import {logoutAuth0} from './user.slice';
import { Mixpanel } from '../../mixpanel';
import { whiteBackground } from '../../utils/colors';
import './account.scss';


const {SubMenu} = Menu;
const {Sider, Content} = Layout;

function Account({children}) {
    const location = useLocation();
    const dispatch = useDispatch();
    const { logout } = useAuth0();

    const navigate = useNavigate();

    const [selectedKeys, setSelectedKeys] = useState([]);

    useEffect(() => {
        setSelectedKeys([location.pathname.replace('/account/', '')]);
    }, [location.pathname]);

    const handleLogout = () => {
        Mixpanel.track('Logout');
        logout({ localOnly: true }) // localOnly to not redirect to auth0 logout page
        dispatch(logoutAuth0());
        navigate('/login'); // we redirect to login page
    };

    return (
        <Layout style={{ height: '100%', minHeight: '100%', backgroundColor: '#EBEBEB', display: 'flex' }}>
            <Sider style={{ height: '92vh', minHeight: '100%', overflow: 'hidden' }}>
                <Menu
                    className='accountMenu'
                    mode="inline"
                    style={{ height: '101%', background: whiteBackground}}
                    defaultOpenKeys={['account']}
                    selectedKeys={selectedKeys}
                >
                <SubMenu key="account" title="Account" icon={<BarChartOutlined />}>
                    <Menu.Item key="profile" icon={<UserOutlined/>}><Link to ="profile">Profile</Link></Menu.Item>
                    <Menu.Item key="integrations" icon={<LinkOutlined />}><Link to="integrations">Integrations</Link></Menu.Item>
                    <Menu.Item key="logout" icon={<LogoutOutlined/>} onClick={() => handleLogout()}>Logout</Menu.Item>
                </SubMenu>
                </Menu>
            </Sider>
            <Layout style={{ height: '50%', backgroundColor: '#EBEBEB'  }}>
                <Content  className='accountContent' style={{ display: 'flex', flexDirection: 'column', height: '100%'}}>
                    <Outlet />
                </Content>
            </Layout>
        </Layout>
    );
}

export default Account;
