import { InfoOutlined } from '@mui/icons-material';

export const getSubtabType = (subtab) => {
    const type = subtab.type ?? subtab.content.type ?? "unknown";
    if (type === "metabase") {
        return "dashboard";
    } else if (type === "carto") {
        return "map";
    } else {
        return type;
    }
};

export function escapeValue(value) {
    if (typeof value === 'string') {
        return value.replace(/'/g, "\\'");
    }
    return value;
}

export function arrayToString(array) {
    if (!Array.isArray(array) || array.length === 0) {
        return "()";
    } else {
        return `(${array.map(item => `'${escapeValue(item)}'`).join(', ')})`;
    }
}

export async function loadSVGDataUrl(svgFilePath) {
    try {
      const response = await fetch(svgFilePath);
      const svgText = await response.text();

      return `data:image/svg+xml;charset=utf-8,${encodeURIComponent(svgText)}`;
    } catch (error) {
      console.error("Error loading SVG:", error);
      return null;
    }
}

export function removeElementFromArray(array, element) {
    const index = array.indexOf(element);
    if (index !== -1) {
        array.splice(index, 1);
        return true;
    }
    return false;
}

export function createWidgetActions(tooltipText) {
    return [
        {
            action: function noRefCheck(){},
            icon: <InfoOutlined />,
            id: 'a1',
            name: 'Autostyle',
            tooltip: {
                text: tooltipText,
            }
        }
    ];
}

export function parseQueryString(queryString) {
    const params = new URLSearchParams(queryString);
    const result = {};

    for (const [key, value] of params) {
      if (!result[key]) {
        result[key] = [];
      }
      result[key].push(value);
    }

    return result;
  }
