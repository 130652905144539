import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Typography } from '@mui/material';
import {
    addLayer,
    removeLayer,
    addSource,
    removeSource,
    setViewState,
  } from '@carto/react-redux';
import createCommutingSource from '../../../sources/commuting-source';
import { COMMUTING_LAYER_ID } from '../../layers/commuting-layer';
import createCensusPopulationSource from '../../../sources/census-population-source';
import { CENSUS_POPULATION_LAYER_ID } from '../../layers/census-population-layer';
import createMedianEarningsSource from '../../../sources/median-earnings-source';
import { MEDIAN_EARNINGS_LAYER_ID } from '../../layers/median-earnings-layer';
import createWorkFromHomeSource from '../../../sources/work-from-home-source';
import { WORK_FROM_HOME_LAYER_ID } from '../../layers/work-from-home-layer';
import { WrapperWidgetUI } from '@carto/react-ui';
import { updateLayer } from '@carto/react-redux/';
import { clearLandLordIntelSelected } from '../../../store/market-share-slice';
import './maps.scss';


function Demographics() {
    const dispatch = useDispatch();
    const location = useLocation();
    const { subtab } = location.state;
    const viewState = useSelector((state) => state.carto.viewState);

    const demographics = useSelector((state) => state.demographics);
    dispatch(clearLandLordIntelSelected())

    useEffect(() => {
        const commutingSource = createCommutingSource();
        dispatch(addSource(commutingSource));
        dispatch(addLayer({ id: COMMUTING_LAYER_ID, source: commutingSource.id }));
        const censusPopulationSource = createCensusPopulationSource();
        dispatch(addSource(censusPopulationSource));
        dispatch(addLayer({ id: CENSUS_POPULATION_LAYER_ID, source: censusPopulationSource.id }));
        const medianEarningsSource = createMedianEarningsSource();
        dispatch(addSource(medianEarningsSource));
        dispatch(addLayer({ id: MEDIAN_EARNINGS_LAYER_ID, source: medianEarningsSource.id }));
        const workFromHomeSource = createWorkFromHomeSource();
        dispatch(addSource(workFromHomeSource));
        dispatch(addLayer({ id: WORK_FROM_HOME_LAYER_ID, source: workFromHomeSource.id }));

        if(viewState.latitude === 0 && viewState.longitude === 0 && viewState.zoom === 0) {
            const latitude = subtab?.setup?.latitude || 0;
            const longitude = subtab?.setup?.longitude || 0;
            const zoom = subtab?.setup?.zoom || 3;
            dispatch(setViewState({ latitude: latitude, longitude: longitude, zoom: zoom, transitionDuration: 500 }));
        }


        return () => {
            dispatch(removeLayer(COMMUTING_LAYER_ID));
            dispatch(removeSource(commutingSource.id));
            dispatch(removeLayer(CENSUS_POPULATION_LAYER_ID));
            dispatch(removeSource(censusPopulationSource.id));
            dispatch(removeLayer(MEDIAN_EARNINGS_LAYER_ID));
            dispatch(removeSource(medianEarningsSource.id));
            dispatch(removeLayer(WORK_FROM_HOME_LAYER_ID));
            dispatch(removeSource(workFromHomeSource.id));
        };
    }, [dispatch, subtab]);

    useEffect(() => {
        dispatch(
            updateLayer({
                id: 'commutingLayer',
                layerAttributes: {
                    visible: demographics.commutingLayerVisible,
                    legend : {
                        collapsed : !demographics.commutingLayerVisible,
                    }
                }
            })
        );
        dispatch(
            updateLayer({
                id: 'censusPopulationLayer',
                layerAttributes: {
                    visible: demographics.censusPopulationLayerVisible,
                    legend : {
                        collapsed : !demographics.censusPopulationLayerVisible,
                    }
                }
            })
        );
        dispatch(
            updateLayer({
                id: 'medianEarningsLayer',
                layerAttributes: {
                    visible: demographics.medianEarningsLayerVisible,
                    legend : {
                        collapsed : !demographics.medianEarningsLayerVisible,
                    }
                }
            })
        );
        dispatch(
            updateLayer({
                id: 'workFromHomeLayer',
                layerAttributes: {
                    visible: demographics.workFromHomeLayerVisible,
                    legend : {
                        collapsed : !demographics.workFromHomeLayerVisible,
                    }
                }
            })
        );
    }, [dispatch, demographics]);

    return (
        <Grid container direction='column' spacing={2}>
            <Grid item sx={{
                    maxWidth:'420px !important',
                    '& section' : {
                        maxWidth:'100% !important',
                        width:'100% !important',
                        '& div' : {
                            maxWidth:'100% !important',
                        }
                    }
                }}>
                <WrapperWidgetUI
                        title={`Commuting Flows per County`}
                >
                    <Typography variant="body2" display="block" gutterBottom sx={{
                        margin: '0px',
                        marginBottom: '0px',
                        }}>
                        The movement of workers from their place of residence to their place of work. It measures the number of individuals commuting for employment purposes, providing insights into regional labor mobility. (Data from the United States Census Bureau). Toggle this layer on the legend (bottom-right) to see this dataset
                    </Typography>
                </WrapperWidgetUI>
            </Grid>
            <Grid item sx={{
                    maxWidth:'420px !important',
                    '& section' : {
                        maxWidth:'100% !important',
                        width:'100% !important',
                        '& div' : {
                            maxWidth:'100% !important',
                        }
                    }
                }}>
                <WrapperWidgetUI
                        title={`Census Population per Block Group`}
                >
                    <Typography variant="body2" display="block" gutterBottom sx={{
                        margin: '0px',
                        marginBottom: '0px',
                        }}>
                        The total number of people living in a block group, which is a statistical division of census tracts. A block group is typically made up of several city blocks. (Data from the United States Census Bureau). Toggle this layer on the legend (bottom-right) to see this dataset.
                    </Typography>
                </WrapperWidgetUI>
            </Grid>
            <Grid item sx={{
                    maxWidth:'420px !important',
                    '& section' : {
                        maxWidth:'100% !important',
                        width:'100% !important',                        '& div' : {
                            maxWidth:'100% !important',
                        }
                    }
                }}>
                <WrapperWidgetUI
                        title={`Median Earnings per Tract`}
                >
                    <Typography variant="body2" display="block" gutterBottom sx={{
                        margin: '0px',
                        marginBottom: '0px',
                        }}>
                        The median earnings of workers within a specific census tract. A census tract is a small, relatively permanent statistical subdivision of a county.  ( Data from the American Community Survey (ACS)). Toggle this layer on the legend (bottom-right) to see this dataset.
                    </Typography>
                </WrapperWidgetUI>
            </Grid>
            <Grid item sx={{
                    maxWidth:'420px !important',
                    '& section' : {
                        maxWidth:'100% !important',
                        width:'100% !important',
                        '& div' : {
                            maxWidth:'100% !important',
                        }
                    }
                }}>
                <WrapperWidgetUI
                        title={`Number of Workers Working from Home per County`}
                >
                    <Typography variant="body2" display="block" gutterBottom sx={{
                        margin: '0px',
                        marginBottom: '0px',
                        }}>
                        The total number of employed individuals in a given county who primarily work from their residence, rather than commuting to a workplace. This data reflects the prevalence of remote work within a specific county. (Data from the American Community Survey (ACS)). Toggle this layer on the legend (bottom-right) to see this dataset.
                    </Typography>
                </WrapperWidgetUI>

            </Grid>
        </Grid>
    );
}

export default Demographics;
