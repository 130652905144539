import deepmerge from 'deepmerge';
import { createTheme } from '@mui/material';
import { cartoThemeOptions } from '@carto/react-ui';
import { brandPurple } from './utils/colors'

const customTheme = {
    palette: {

        background: {
            paper: '#F9F9F9'
        },
        secondary: {
            main: brandPurple,
        },
    },
    components: {
        MuiPaper: {
            styleOverrides: {
                root: {
                    backgroundColor: '#F9F9F9',
                },
            }
        },
        MuiBox: {
            styleOverrides: {
                root: {
                    background: '#F9F9F9' ,
                },
            }
        }
    }
};

const cartoTheme = createTheme(deepmerge(cartoThemeOptions, customTheme));

export default cartoTheme;
