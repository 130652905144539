import { Typography } from '@mui/material';
import { disclaimerText } from '../../../../utils/colors';


export function DataDisclaimer() {

    return (
        <>
            <Typography variant="body2" display="block" gutterBottom sx={{
                margin: '20px',
                marginBottom: '0px',
                color: disclaimerText
            }}>
                * Data is only available for markets you have purchased.
            </Typography>
            <Typography variant="body2" display="block" gutterBottom sx={{
                margin: '26px',
                marginTop: '0px',
                color: disclaimerText
            }}>
                All the values shown are for the selected area on the map
            </Typography>
        </>
    );
}

export default DataDisclaimer;
