import { useSelector, useDispatch } from 'react-redux';
import { CartoLayer } from '@deck.gl/carto';
import { selectSourceById } from '@carto/react-redux';
import { useCartoLayerProps } from '@carto/react-api';
import { hexToRGB, brandPurple } from '../../../../utils/colors';
import { setLandLordIntelSelected, clearLandLordIntelSelected } from '../../store/market-share-slice';
import './legend-with-closed-points.scss'

export const LANDLORD_INTEL_LAYER_ID = 'landlordIntelLayer';


export default function LandlordIntelLayer() {
    const dispatch = useDispatch();

    const { landlordIntelLayer } = useSelector((state) => state.carto.layers);
    const landlordVisibility = useSelector((state) => state.marketShare.showLandlordIntel);
    const source = useSelector((state) => selectSourceById(state, landlordIntelLayer?.source));
    const cartoLayerProps = useCartoLayerProps({ source });

    if (landlordIntelLayer && source) {
        return new CartoLayer({
            ...cartoLayerProps,
            title: 'Landlord Intel',
            id: LANDLORD_INTEL_LAYER_ID,
            getFillColor: (d) => hexToRGB(brandPurple, 204),
            getLineColor: (d) => hexToRGB(brandPurple, 204),
            pointRadiusMinPixels: 8,
            lineWidthMinPixels: 0,
            pickable: true,
            visible: landlordVisibility,
            loadOptions: {
                fetch: {
                    headers: {
                        'cache-control': 'no-cache, max-age=0'
                    }
                }
            },
            onDataLoad: (data) => {
                cartoLayerProps.onDataLoad && cartoLayerProps.onDataLoad(data);
            },
            onDataError: (error) => { console.log(error) },
            onClick: (info) => {
                if (info && info.object) {
                    dispatch(setLandLordIntelSelected({
                        object: info.object,
                        screenCoords: { x: info.x, y: info.y },
                    }));
                    console.log('Feature clicked:', info.object);
                } else {
                    dispatch(clearLandLordIntelSelected());
                }
            },
        });
    }
}
