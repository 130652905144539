import { Grid, Typography, Stack, Box } from '@mui/material';
import {  useDispatch, useSelector } from 'react-redux';
import { Switch } from 'antd';
import { addStatus, removeStatus, showLandlordIntel, hideLandLordIntel, clearLandLordIntelSelected } from '../../store/market-share-slice';
import { createWidgetActions }  from '../../../../utils/utils';
import { disclaimerText } from '../../../../utils/colors';
import { WrapperWidgetUI } from '@carto/react-ui';


export function LayerSelect() {
    const dispatch = useDispatch();

    const centreStatus = useSelector((state) => state.marketShare.centreStatus);
    const landlordVisibility = useSelector((state) => state.marketShare.showLandlordIntel);

    const toggleStatus = (status, active) => {
        if (active) {
            dispatch(addStatus(status));
        } else {
            dispatch(removeStatus(status));
        }
    };

    const toggleLandlordIntelVisibility = (active) => {
        if (active) {
            dispatch(showLandlordIntel());
        } else {
            dispatch(hideLandLordIntel());
            dispatch(clearLandLordIntelSelected());
        }
    }

    return (
        <>
            { (!landlordVisibility) ? (
                <WrapperWidgetUI
                    title="Centres"
                    actions={createWidgetActions('By toggling the button on this widget, you can select which  centres state  you want to display on the screen. By default, all centers are displayed.')}
                >
                    <Box sx={{
                            width: '100%',
                            marginTop: '20px',
                            paddingTop: 0,
                        }} alignItems='center' >
                        <Grid container direction='column' alignItems='center' >
                            <Box sx={{ width: '100%', padding: '4px'}}>
                                <Stack direction="row" spacing={2} alignItems="center">
                                    <Grid
                                        container
                                        direction='row'
                                        alignItems='center'
                                    >
                                        <Typography variant='body2'>Open Centres</Typography>
                                    </Grid>
                                    <Switch
                                        name='Open Centres'
                                        checked={centreStatus.includes('OPEN')}
                                        onChange={(e) => toggleStatus('OPEN', e)}
                                    />
                                </Stack>
                            </Box>
                        </Grid>
                        <Grid container direction='row' alignItems='center'>
                            <Box sx={{ width: '100%', padding: '4px' }}>
                                <Stack direction="row" spacing={1} alignItems="center">
                                    <Grid
                                        container
                                        direction='row'
                                        alignItems='center'
                                    >
                                        <Typography variant='body2'>Recently closed (last 24 mo)</Typography>
                                    </Grid>
                                    <Switch
                                        name='Recently closed (last 24 mo)'
                                        checked={centreStatus.includes('CLOSED')}
                                        onChange={(e) => toggleStatus('CLOSED', e)}
                                    />
                                </Stack>
                            </Box>
                        </Grid>
                    </Box>
                </WrapperWidgetUI>
            ) : <></> }
            <WrapperWidgetUI
                title="Landlord Intel"
                actions={createWidgetActions('')}
            >
                <Box sx={{
                        width: '100%',
                        marginTop: '20px',
                        paddingTop: 0,
                    }} alignItems='center' >
                    <Grid container direction='column' alignItems='center' >
                        <Box sx={{ width: '100%', padding: '4px'}}>
                            <Stack direction="row" spacing={2} alignItems="center">
                                <Grid
                                    container
                                    direction='row'
                                    alignItems='center'
                                >
                                    <Typography variant='body2'>Show landlord info</Typography>
                                </Grid>
                                <Switch
                                    name='Landlord Intel'
                                    checked={landlordVisibility}
                                    onChange={(e) => toggleLandlordIntelVisibility(e)}
                                />
                            </Stack>
                            <Typography variant="caption" display="block" gutterBottom sx={{
                                margin: '6px',
                                marginTop: '10px',
                                marginBottom: '0px',
                                color: disclaimerText
                            }}>
                                Click on the point to get more info about the landlord or contact us
                            </Typography>
                        </Box>
                    </Grid>
                </Box>
            </WrapperWidgetUI>
        </>
    );
}

export default LayerSelect;
