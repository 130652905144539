import { useSelector, useDispatch } from 'react-redux';
import { CartoLayer } from '@deck.gl/carto';
import { selectSourceById, updateLayer } from '@carto/react-redux';
import { useCartoLayerProps } from '@carto/react-api';
import { LEGEND_TYPES } from '@carto/react-ui';
import { hexToRGB, demorgaphicPallette } from '../../../../utils/colors';
import htmlForFeature, { FORMATTER_TYPES } from '../../../../utils/htmlForFeature';

export const WORK_FROM_HOME_LAYER_ID = 'workFromHomeLayer';

export const CATEGORY_COLORS = {
    '0.0 < 100.0' : hexToRGB(demorgaphicPallette[0], 204),
    '100.0 - 500.0' : hexToRGB(demorgaphicPallette[1], 204),
    '500.0 - 1000.0' : hexToRGB(demorgaphicPallette[2], 204),
    '1000.0 - 5000.0' : hexToRGB(demorgaphicPallette[3], 204),
    '5000.0 - 15000.0' : hexToRGB(demorgaphicPallette[4], 204),
    '15000.0 - 30000.0'  : hexToRGB(demorgaphicPallette[5], 204),
    '30000.0 - 45000.0'  : hexToRGB(demorgaphicPallette[6], 204),
    '45000.0 - 60000.0'  : hexToRGB(demorgaphicPallette[7], 204),
    '> 60000.0'  : hexToRGB(demorgaphicPallette[8], 204),
};

const DATA = Object.entries(CATEGORY_COLORS).map((elem) => {
    return { color: elem[1], label: elem[0] };
});

export default function WorkFromHomeLayer() {
    const dispatch = useDispatch();
    const { workFromHomeLayer } = useSelector((state) => state.carto.layers);
    const source = useSelector((state) => selectSourceById(state, workFromHomeLayer?.source));
    const demographics = useSelector((state) => state.demographics);
    const cartoLayerProps = useCartoLayerProps({ source });


    const layerConfig = {
        title: 'Work From Home',
        visible: demographics.workFromHomeLayerVisible,
        switchable: true,
        legend: {
            collapsible: true,
            collapsed: !demographics.workFromHomeLayerVisible,
            type: LEGEND_TYPES.CATEGORY,
            labels: DATA.map((data) => data.label),
            colors: DATA.map((data) => data.color),
        },
    };


    if (workFromHomeLayer && source) {
        return new CartoLayer({
            ...cartoLayerProps,
            id: WORK_FROM_HOME_LAYER_ID,
            geoColumn: 'geom',
            lineWidthMinPixels: 1,
            visible: workFromHomeLayer.visible,
            getFillColor: (object) => {
                const value = Math.floor(object.properties.workers_who_worked_from_home || 0).toFixed(0);
                return value < 100.0 && value >= 0 ? hexToRGB(demorgaphicPallette[0], 204) :
                    value < 500.0 && value >= 100.0 ? hexToRGB(demorgaphicPallette[1], 204) :
                    value < 1000.0 && value >= 500.0 ? hexToRGB(demorgaphicPallette[2], 204) :
                    value < 5000.0 && value >= 1000.0 ? hexToRGB(demorgaphicPallette[3], 204) :
                    value < 15000.0 && value >= 5000.0 ? hexToRGB(demorgaphicPallette[4], 204) :
                    value < 30000.0 && value >= 15000.0 ? hexToRGB(demorgaphicPallette[5], 204) :
                    value < 450000.0 && value >= 30000.0 ? hexToRGB(demorgaphicPallette[6], 204) :
                    value < 60000.0 && value >= 450000.0 ? hexToRGB(demorgaphicPallette[7], 204) :
                    value >= 60000.0 ? hexToRGB(demorgaphicPallette[8], 204) : hexToRGB(demorgaphicPallette[8], 204);
            },
            getLineColor: (object) => {
                const value = Math.floor(object.properties.workers_who_worked_from_home || 0).toFixed(0);
                return value < 100.0 && value >= 0 ? hexToRGB(demorgaphicPallette[0], 204) :
                    value < 500.0 && value >= 100.0 ? hexToRGB(demorgaphicPallette[1], 204) :
                    value < 1000.0 && value >= 500.0 ? hexToRGB(demorgaphicPallette[2], 204) :
                    value < 5000.0 && value >= 1000.0 ? hexToRGB(demorgaphicPallette[3], 204) :
                    value < 15000.0 && value >= 5000.0 ? hexToRGB(demorgaphicPallette[4], 204) :
                    value < 30000.0 && value >= 15000.0 ? hexToRGB(demorgaphicPallette[5], 204) :
                    value < 450000.0 && value >= 30000.0 ? hexToRGB(demorgaphicPallette[6], 204) :
                    value < 60000.0 && value >= 450000.0 ? hexToRGB(demorgaphicPallette[7], 204) :
                    value >= 60000.0 ? hexToRGB(demorgaphicPallette[8], 204) : hexToRGB(demorgaphicPallette[8], 204);
            },
            pickable: true,
            onDataLoad: (data) => {
                dispatch(
                    updateLayer({ id: WORK_FROM_HOME_LAYER_ID, layerAttributes: { ...layerConfig } })
                );
                cartoLayerProps.onDataLoad && cartoLayerProps.onDataLoad(data);
            },
            onDataError: (error) => { console.log(error) },
            onHover: (info) => {
                if (info?.object) {
                    console.log(info.object)
                    info.object = {
                        html: htmlForFeature({
                            feature: info.object,
                            formatters: [
                                {
                                    type: FORMATTER_TYPES.NUMBER,
                                    column: 'workers_who_worked_from_home',
                                },
                                {
                                    type: FORMATTER_TYPES.PERCENTAGE,
                                    column: 'percent_of_workers_who_worked_at_home',
                                }
                            ],
                            includeColumns: [
                                {'originalName':'name', 'alias' : 'Name'},
                                {'originalName':'state', 'alias' : 'State'},
                                {'originalName':'workers_who_worked_from_home', 'alias' : 'Workers'},
                                {'originalName':'percent_of_workers_who_worked_at_home', 'alias' : 'Percentage'},
                            ],
                         }),
                        style: {},
                    };
                }
            },
        });
    }
}
