import { object, string, number, array } from 'yup';

export const SQUELETON = {
    template: [
        {
            tab: "Benchmarking",
            icon: "TrophyOutlined",
            type: "metabase",
            subtabs: [
                {
                    title: "Supply",
                    content: {
                    id: "",
                    type: "metabase"
                    },
                    filters: {}
                },{
                    title: "Public Data",
                    type: "metabase",
                    content: {
                            id: "",
                            type: "metabase"
                        },
                    filters: {}
                },{
                    title: "Deals",
                    type: "metabase",
                    subtabs: [
                        {
                            title: "",
                            content: {
                                id: "",
                                type: "metabase"
                            },
                            filters: {}
                        },
                    ]
                }
            ]
        },{
            tab: "Find New Sites",
            icon: "CompassOutlined",
            type: "carto",
            subtabs: [
                {
                    title: "Market Share",
                    content: {
                        type: "carto"
                    },
                    filters: {},
                    not_filters: {},
                    setup: {
                        latitude: 0,
                        longitude: 0,
                        zoom: 6
                    }
                },{
                    title: "Flex Price",
                    content: {
                        type: "carto"
                    },
                    filters: {},
                    not_filters: {},
                    setup: {
                        latitude: 0,
                        longitude: 0,
                        zoom: 6
                    }
                },{
                    title: "Market Demand",
                    content: {
                        type: "carto"
                    },
                    filters: {},
                    not_filters: {},
                    setup: {
                        latitude: 0,
                        longitude: 0,
                        zoom: 6
                    }
                }
            ],
        }
    ]
}

const setupSchema = object({
    latitude: number().optional(),
    longitude: number().optional(),
    zoom: number().optional(),
});

const contentSchema = object({
    id: string().when('type', {
        is: 'metabase',
        then: (schema) => schema.required('ID is required for metabase type'),
    }),
    type: string().oneOf(['metabase', 'carto']).required('Content type is required. It should be metabase or carto'),
});

const subtabSchema = object({
    title: string().required('Subtab title is required'),
    content: contentSchema,
    filters: object().optional(),
    not_filters: object().optional(),
    setup: object().when('content', {
        is: 'carto',
        then: setupSchema.optional(),
    }),
    type: string().oneOf(['metabase']).when( 'title', {
        is: 'Deals',
        then: (schema) => schema.required('Subtab type is required for Deals subtab'),
    }),
    subtabs: array().of(
        object({
            title: string().required('Title is required'),
            content: contentSchema,
            filters: object().optional(),
            not_filters: object().optional(),
        })
    ).when( 'title', {
        is: 'Deals',
        then: (schema) => schema.required('Subtab subtabs is required for Deals subtab'),
    }),
});

const templateSchema = array().of(
    object({
        tab: string().required('Tab is required'),
        icon: string().required('Icon is required'),
        type: string().oneOf(['metabase', 'carto']).optional(),
        subtabs: array().of(subtabSchema).required('Subtabs are required'),
    })
);

const finalSchema = object({
    template: templateSchema,
});

export const validateTemplate = (template) => {
    return finalSchema.validateSync(template, { strict: true });
};
