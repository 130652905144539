import { useSelector, useDispatch } from 'react-redux';
import { CartoLayer } from '@deck.gl/carto';
import { selectSourceById, updateLayer } from '@carto/react-redux';
import { useCartoLayerProps } from '@carto/react-api';
import htmlForFeature from '../../../../utils/htmlForFeature';
import { hexToRGB, brandPurple, white } from '../../../../utils/colors';
import { LEGEND_TYPES } from '@carto/react-ui';
import './legend-with-closed-points.scss'

export const MARKET_DEMAND_LAYER_ID = 'marketDemandLayer';

const chartColor = hexToRGB(brandPurple, 204);
const disabledChartColor = hexToRGB(white, 204);

export const CATEGORY_COLORS = {
    'Included': chartColor ,
    'Less than 3 enquiries': disabledChartColor,
};

const DATA = Object.entries(CATEGORY_COLORS).map((elem) => {
    return { color: elem[1], label: elem[0] };
});

const layerConfig = {
    title: 'Locations included in reporting',
    visible: true,
    switchable: false,
    legend: {
        collapsible: false,
        type: LEGEND_TYPES.CATEGORY,
        labels: DATA.map((data) => data.label),
        colors: DATA.map((data) => data.color),
    },
};

export default function MarketDemandLayer() {
    const dispatch = useDispatch();
    const { marketDemandLayer } = useSelector((state) => state.carto.layers);
    const source = useSelector((state) => selectSourceById(state, marketDemandLayer?.source));
    const cartoLayerProps = useCartoLayerProps({ source });

    if (marketDemandLayer && source) {
        return new CartoLayer({
            ...cartoLayerProps,
            id: MARKET_DEMAND_LAYER_ID,
            getFillColor: (d) => chartColor,
            getLineColor: (d) => chartColor,
            pointRadiusMinPixels: 8,
            lineWidthMinPixels: 0,
            pickable: true,
            loadOptions: {
                fetch: {
                    headers: {
                        'cache-control': 'no-cache, max-age=0'
                    }
                }
            },
            onDataLoad: (data) => {
                dispatch(
                    updateLayer({ id: MARKET_DEMAND_LAYER_ID, layerAttributes: { ...layerConfig } })
                );
                cartoLayerProps.onDataLoad && cartoLayerProps.onDataLoad(data);
            },
            onDataError: (error) => { console.log(error) },
            onHover: (info) => {
                if (info?.object) {
                    info.object = {
                        html: htmlForFeature({
                            feature: info.object,
                            includeColumns: [
                                /*{'originalName':'operator_name', 'alias' : 'Operator Name'},
                                {'originalName':'parent_operator_name', 'alias' : 'Parent Operator Name'},*/
                                {'originalName':'city', 'alias' : 'City'},
                                /*{'originalName':'address', 'alias' : 'Address'},*/
                                {'originalName':'district_segmentation_name', 'alias' : 'District'},
                                /*{'originalName':'postcode', 'alias' : 'Postcode'},*/
                            ],
                        }),
                        style: {},
                    };
                }
            },
        });
    }
}
