import { MAP_TYPES } from '@deck.gl/carto';

export const COMMUTING_SOURCE_ID = 'commutingSource';

export const COMMUTING_CARTO_ORIGIN = 'carto-dw-ac-l5aq60v6.shared_us.usa_commuting_county'

export default function createCommutingSource() {
    const baseQuery = `SELECT * FROM ${COMMUTING_CARTO_ORIGIN}`;

    return {
        id: COMMUTING_SOURCE_ID,
        type: MAP_TYPES.QUERY,
        connection: 'carto_dw',
        data: baseQuery,
        isDroppingFeatures: false
    };
}
