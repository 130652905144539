
import React, { useMemo } from 'react';
import { Menu } from 'antd';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import userStatus from '../modules/user/user-status';
import DynamicIcon from './dynamic-icon.component';
import { brandPurple, menuItemNotSelectedText } from '../utils/colors';
import { MoreOutlined } from '@ant-design/icons';

const getStyle = (isActive) => ({
    fontWeight: "bold",
    color: isActive ? brandPurple : menuItemNotSelectedText,
    backgroundColor: isActive ? "white" : "transparent",
    borderRadius: isActive ? "25px" : "0",
    padding: "10px 30px",
});

const getStyleAdmin = (isActive) => ({
    fontWeight: "bold",
    color: isActive ? brandPurple : menuItemNotSelectedText,
    backgroundColor: isActive ? "white" : "transparent",
    borderRadius: isActive ? "25px" : "0",
    padding: "10px 30px",
});

const MenuBar = ({ template, currentPath }) => {

    const userState = useSelector((state) => state.user);
    const profile = userState.profile;
    const profileStatus = profile.status;
    const profileRole = profile.role;

    const menuItems = useMemo(() => profileStatus === userStatus.ACTIVE ? template.map((item) => (
        <Menu.Item key={item.tab} style={{ float: 'left' }}>
            <NavLink
                to={`${encodeURIComponent(item.tab)}`}
                state={{ currentTab: item }}
                style={({ isActive }) => getStyle(isActive)}
            >
                {item.tab}
            </NavLink>
        </Menu.Item>
    )) : [], [profileStatus, template]);

    return (
        <Menu theme="dark" mode="horizontal" selectedKeys={currentPath} style={{ display: 'flex', justifyContent: 'space-between' }}>
            {menuItems}
            {
                profileRole === 'admin' ? adminMenu() : accountItem()
            }
        </Menu>
    );
};

function adminMenu() {
    return <Menu.SubMenu key="admin" style={{  marginLeft: 'auto' }} triggerSubMenuAction='hover' icon={<MoreOutlined style={{ fontSize: '20px' }} />}>
        {accountItem()}
        {adminItem()}
    </Menu.SubMenu>
}


function accountItem() {
    return <Menu.Item key="account" style={{ marginLeft: 'auto', marginRigh: '0px' }}>
        <NavLink
            to="/account"
            style={({ isActive }) => getStyleAdmin(isActive)}
        >
            <span style={{ marginRight: '10px' }}>
                <DynamicIcon iconName={'UserOutlined'} />
            </span>
            My Account
        </NavLink>
    </Menu.Item>
}



function adminItem() {
    return <Menu.Item key="admin" style={{ marginLeft: 'auto', marginRigh: '0px' }}>
        <NavLink
            to="/admin"
            style={({ isActive }) => getStyleAdmin(isActive)}
        >
            <span style={{ marginRight: '10px' }}>
                <DynamicIcon iconName={'ControlOutlined'} />
                {/* <DynamicIcon iconName={'AuditOutlined'} /> */}
            </span>
            User Admin
        </NavLink>
    </Menu.Item>
}

export default React.memo(MenuBar);
