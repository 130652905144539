import api from '../../api';

export const integrationService = {
  getSpaces,
  getMetric,
  getSummary,
  getMarketComps,
  getSummaryComps,
  getSummaryCompsUser,
  getMetricComps,
  getMetricCompsUser,
  getUniqueResourceTypes,
  getCompsets,
  connect,
  update,
  cobotConnect,
  andcardsConnect
};

function getSpaces(integrationId, startDate = '', endDate = '') {
  return api
    .get(`/integrations/${integrationId}/spaces`, {params: {startDate, endDate}})
    .then((res) => res.data)
    .catch(() => {});
}

function getCompsets() {
  return api
    .get(`/comp_set/my_compsets`)
    .then((res) => res.data)
    .catch(() => {});
}

function getMetric({integrationId, metric = '', spaceId = '', resourceType = '', startDate = '', endDate = ''} = {}) {
  // return api
  //   .get(`/integrations/${integrationId}/${metric}`, {params: {officeId: spaceId === 'all' ? undefined : spaceId, startDate, endDate}})
  //   .then((res) => res.data);
  return api
    .get(`/integrations/${integrationId}/${metric}`, {params: {officeId: spaceId === 'all' ? undefined : spaceId, resourceType: resourceType === 'all' ? undefined : resourceType, startMonth: startDate, endMonth: endDate}})
    .then((res) => res.data)
    .catch(() => {});
}

function getSummary({integrationId, spaceId = '', resourceType = '', startMonth = ''} = {}) {
  const source = api.getCancelToken();
  return [source, api.get(`/integrations/${integrationId}/summary`, {
      cancelToken: source.token,
      params: {
        officeId: spaceId === 'all' ? undefined : spaceId,
        resourceType: resourceType === 'all' ? undefined : resourceType,
        startMonth
      }
    })
    .then((res) => res.data)];
}

function getMarketComps({integrationId, spaceId = '', resourceType = '', startMonth = ''} = {}) {
  const source = api.getCancelToken();
  const req_summary = api.get(`/integrations/${integrationId}/summary`, {
      cancelToken: source.token,
      params: {
        officeId: spaceId === 'all' ? undefined : spaceId,
        resourceType: resourceType === 'all' ? undefined : resourceType,
        startMonth
      }
    })
    .then((res) => res.data);

  const req_market_comps = api.get(`/integrations/${integrationId}/market_comps`, {
      cancelToken: source.token,
      params: {
        officeId: spaceId === 'all' ? undefined : spaceId,
        resourceType: resourceType === 'all' ? undefined : resourceType,
        startMonth
      }
    })
    .then((res) => res.data);

  const result = Promise.all([req_summary, req_market_comps])

  return [source, result];
}

function getUniqueResourceTypes(integrationId, startDate = '', endDate = '') {
  return api
    .get(`/integrations/${integrationId}/unique_resource_types`, {params: {startDate, endDate}})
    .then((res) => res.data);
}

function connect(data = {}) {
  return api
    .post(`/integrations`, data)
    .then((res) => res.data);
}

function update(integrationId, data = {}) {
    return api
    .put(`/integrations/${integrationId}`, data)
    .then((res) => res.data);
}

function cobotConnect(data = {}) {
  console.log(data);
  return api
    .get('/connectors/cobot/access_token', {params: {code: data.code}})
    .then((res) => connect({type: data.type, credentials: res.data}));
}

function andcardsConnect(data = {}) {
  console.log(data);
  return api
    .get('/connectors/andcards/access_token', {params: {code: data.code}})
    .then((res) => connect({type: data.type, credentials: res.data}));
}

function getSummaryComps({integrationId, spaceId = '', resourceType = '', startMonth = ''} = {}) {
  const source = api.getCancelToken();
  return [source, api.get(`/integrations/${integrationId}/market_comps`, {
      cancelToken: source.token,
      params: {
        officeId: spaceId === 'all' ? undefined : spaceId,
        resourceType: resourceType === 'all' ? undefined : resourceType,
        startMonth
      }
    })
    .then((res) => res.data)];
}

function getSummaryCompsUser({compsetId = 'all', resourceType = '', startMonth = ''} = {}) {
  const source = api.getCancelToken();
  return [source, api.get(`/comp_set/${compsetId}`, {
      cancelToken: source.token,
      params: {
        resourceType: resourceType === 'all' ? undefined : resourceType,
        startMonth
      }
    })
    .then((res) => res.data)];
}

function getMetricComps({integrationId, metric = '', spaceId = '', resourceType = '', startDate = '', endDate = ''} = {}) {
  // return api
  //   .get(`/integrations/${integrationId}/${metric}`, {params: {officeId: spaceId === 'all' ? undefined : spaceId, startDate, endDate}})
  //   .then((res) => res.data);
  return api
    .get(`/integrations/${integrationId}/${metric}`, {params: {officeId: spaceId === 'all' ? undefined : spaceId, resourceType: resourceType === 'all' ? undefined : resourceType, startMonth: startDate, endMonth: endDate}})
    .then((res) => res.data)
    .catch(() => {});
}

function getMetricCompsUser({metric = '', compsetId = 'all', resourceType = '', startDate = '', endDate = ''} = {}) {
  // return api
  //   .get(`/integrations/${integrationId}/${metric}`, {params: {officeId: spaceId === 'all' ? undefined : spaceId, startDate, endDate}})
  //   .then((res) => res.data);
  return api
    .get(`/comp_set/${compsetId}/${metric}`, {params: {resourceType: resourceType === 'all' ? undefined : resourceType, startMonth: startDate, endMonth: endDate}})
    .then((res) => res.data)
    .catch(() => {});
}
