import React from 'react';
import { useLocation } from 'react-router-dom';
import { lazy } from 'react';
import { Layout } from 'antd';
import LazyLoadComponent from '../carto/components/common/lazy-load.component';
import { Mixpanel } from '../../mixpanel';

const MapContainer = lazy(() =>
  import(/* webpackChunkName: 'map-container' */ '../carto/components/views/main/map-container.component')
);
const Sidebar = lazy(() =>
  import(/* webpackChunkName: 'sidebar' */ '../carto/components/views/main/sidebar/sidebar.component')
);

function CartoTab() {

    const location = useLocation();
    const { currentTab } = location.state;

    Mixpanel.track(currentTab.tab);

    return (
        <Layout className='cartoContent' style={{ height: '100%', display: 'flex', flexDirection: 'row', marginTop: '-24px', marginLeft: '-24px', marginRight: '-24px'}}>
            <LazyLoadComponent>
                <Sidebar />
                <MapContainer />
            </LazyLoadComponent>
        </Layout>
    );
}

export default CartoTab;
