import React from 'react';
import {Row, Col, Card, Form, Input, Button} from 'antd';
import {Link} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import './signup.scss';

import {forgotPassword} from './user.slice';

function ForgotPassword() {
  const userState = useSelector((state) => state.user);
  const dispatch = useDispatch();

  function handleFinish(values) {
    dispatch(forgotPassword(values));
  }

  const layout = {
    labelCol: {span: 8},
    wrapperCol: {span: 16}
  };

  const tailLayout = {
    wrapperCol: {offset: 8, span: 16},
  };

  return (
    <div className="signup">
      <Row justify="center">
        <Col lg={8} md={12} sm={12} xs={24}>
          <Card>
            <h2>Forgot password</h2>
            <p>Enter your account email to receive a reset link</p>
            <p>{userState.error}</p>
            <Form {...layout} name="forgot-password" onFinish={handleFinish}>
              <Form.Item
                label="Email"
                name="email"
                rules={[{required: true, message: 'Please input your email!'}]}
              >
                <Input />
              </Form.Item>
              <Form.Item {...tailLayout}>
                <Button type="primary" htmlType="submit" loading={userState.isLoading}>Send</Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
        <Col xl={6} lg={6} sm={8} xs={24}>
          <Card style={{height: '100%'}}>
            <h3>Already have an account?</h3>
            <Link to="/login">Login</Link>

            <h3>New to Coworkintel?</h3>
            <Link to="/signup">Create an account</Link>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default ForgotPassword;
