import { MAP_TYPES } from '@deck.gl/carto';

export const CENSUS_POPULATION_SOURCE_ID = 'censusPopulationSource';

export const CENSUS_POPULATION_CARTO_ORIGIN = 'carto-dw-ac-l5aq60v6.shared_us.census_population_carto_boundaries'

export default function createCensusPopulationSource() {
    const baseQuery = `SELECT * FROM ${CENSUS_POPULATION_CARTO_ORIGIN}`;

    return {
        id: CENSUS_POPULATION_SOURCE_ID,
        type: MAP_TYPES.QUERY,
        connection: 'carto_dw',
        data: baseQuery,
        isDroppingFeatures: false
    };
}
