import { useAuth0 } from "@auth0/auth0-react";
import {Link} from 'react-router-dom';
import React from "react";

export const SignupButton = () => {
  const { loginWithRedirect } = useAuth0();

  const handleSignUp = async () => {
    await loginWithRedirect({
      prompt: "login",
      screen_hint: "signup",
      appState: {
        returnTo: "/login",
      },
    });
  };

  return (
    <Link to="/#" onClick={handleSignUp} style={{'textDecorationLine': 'underline','fontWeight': "underscore", 'color': 'white'}}>
      here
    </Link>
  );
};
