import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import './currency-selector.scss';


export function CurrencySelector({ onCurrencyChange }) {
    const initialCurrency = localStorage.getItem('selectedCurrency') || 'USD';
    const [currency, setCurrency] = React.useState(initialCurrency);

    const handleChange = (event) => {
        const selectedCurrency = event.target.value;
        setCurrency(selectedCurrency);
        localStorage.setItem('selectedCurrency', selectedCurrency);
        onCurrencyChange(selectedCurrency);
    };

    return (
          <Select
            className='currencySelector'
            id="currency-select"
            value={currency}
            onChange={handleChange}
            sx={{ width:'90%', height: 'auto', marginLeft: '20px', marginRight: '20px', marginTop: '10px', borderRadius: "40px"}}
          >
            <MenuItem className="currencyItem" value={"USD"}>USD - United States Dollar</MenuItem>
            <MenuItem className="currencyItem" value={"GBP"}>GBP - Pound Sterling</MenuItem>
            <MenuItem className="currencyItem" value={"EUR"}>EUR - Euro</MenuItem>
          </Select>
    );
  }
