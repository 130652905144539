import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import {Breadcrumb} from 'antd';

function BreadcrumbRouter() {
    const location = useLocation();

  const urls = location.pathname.split('/').filter((u) => u);

  return (
    <Breadcrumb>
      { urls.map((u, index) => {
        const url = `/${urls.slice(0, index + 1).join('/')}`;
        return <Breadcrumb.Item key={index}><Link to={url}>{u}</Link></Breadcrumb.Item>
      }) }
    </Breadcrumb>
  );
}

export default BreadcrumbRouter;
