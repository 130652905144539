
import moment from 'moment';

export function DateXAxisLabel({ x, y, stroke, payload }) {
    return (
        <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-35)" fontSize="10px">
            {moment(payload.value).format('MMM YYYY')}
        </text>
        </g>
    );
};
