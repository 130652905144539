import { MAP_TYPES } from '@deck.gl/carto';
import { arrayToString } from '../../../utils/utils';
import { generateWhereClauses, generateQuery } from '../query-utils';

export const MARKET_SHARE_SOURCE_ID = 'mapSource';

export const MARKET_SHARE_CARTO_ORIGIN = 'carto-dw-ac-l5aq60v6.shared_us.prd_tab1_market_share_17092024'

export default function createMarketShareSource(currentSubtab, centreStatus = []) {
    const baseQuery = `SELECT * FROM ${MARKET_SHARE_CARTO_ORIGIN}`;

    const whereClauses = generateWhereClauses(
        currentSubtab.filters ?? [],
        currentSubtab.not_filters ?? [],
        [`centre_state_clean IN ${arrayToString(centreStatus)}`]
    );

    const finalQuery = generateQuery(whereClauses, baseQuery);

    return {
        id: MARKET_SHARE_SOURCE_ID,
        type: MAP_TYPES.QUERY,
        connection: 'carto_dw',
        data: finalQuery,
        isDroppingFeatures: false
    };
}
