import React, { useEffect } from 'react';
import {Image, Row, Col } from 'antd';
import {useDispatch, useSelector} from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { LoginButton } from '../../components/buttons/login-button.component';
import { SignupButton } from '../../components/buttons/signup-button.component';
import {loginAuth0} from './user.slice';
import loginImage from './login_image.png';
import logo from './logo-white.png'
import spinner from './../../loading_spinner.gif'


function Login() {
  const { user, isLoading, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();
  const userState = useSelector((state) => state.user);
  const isLoadingLogin = isLoading || userState.isLoading;

  useEffect(() => {
    if (isAuthenticated){
      async function login() {
        let accessToken = await getAccessTokenSilently();
        dispatch(loginAuth0(accessToken, user));
      }
      login();
    }
  },
  [getAccessTokenSilently, user?.sub]);


  const antIcon = <img src={spinner} alt="loading..." style={{"background-color":"white"}}/>
  const Loading = () => <div className="app-loading">{antIcon}</div>;


  return (
    <Row>
      {isLoadingLogin ?
        <Col span={24}>
          <Row justify="space-around" align="middle" >
            <Col span={24} style={{
              textAlign: 'center',
              marginTop: '15%'
              }}
            >
              <Loading />
            </Col>
          </Row>
        </Col>
        :
        <>
          <Col xs={0} sm={0} md={0} lg={14} xl={16} style={{
            backgroundColor: "#f9f9f9",
            }}
          >
            <Row justify="space-around" align="middle" style={{
              height: "100vh",
              }}
            >
              <Col span={16}>
                <Image src={loginImage} preview={false}/>
              </Col>
            </Row>
          </Col>
          <Col xs={24} sm={24} md={24} lg={10} xl={8} style={{
            backgroundColor: "#16003d",
            }}
          >
            <Row justify="space-around" align="middle" style={{
              height: "100vh",
            }}>
              <Col span={16} style={{
              color: "white",
            }}>
                <Row>
                  <Image src={logo} preview={false}/>
                </Row>
                <Row style={{
                  marginTop: 15 ,
                  justifyContent: 'center',
                  textAlign: 'center'
                }}>
                  <p>
                    Our data platform connects to tens of thousands of spaces to deliver unique market insights on supply, demand, pricing & revenue.
                  </p>
                </Row>
                <Row>
                  <LoginButton/>

                </Row>
                <Row style={{
                  marginTop: 40 ,
                  justifyContent: 'center'
                }}>
                  <p>
                  Not registered? Sign up <SignupButton/>.</p>
                </Row>
              </Col>
            </Row>
          </Col>
        </>
      }
    </Row>
  );
}

export default Login;
