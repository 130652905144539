import { useSelector } from 'react-redux';
import { CartoLayer } from '@deck.gl/carto';
import { selectSourceById } from '@carto/react-redux';
import { useCartoLayerProps } from '@carto/react-api';
import { hexToRGB, white, brandPurple } from '../../../../utils/colors';

export const MARKET_DEMAND_NO_ENQUIRY_LAYER_ID = 'marketDemandNoEnquiryLayer';

const chartColor = hexToRGB(white, 128);
const chartColorLine = hexToRGB(brandPurple, 204);

export default function MarketDemandNoEnquiryLayer() {
    const { marketDemandNoEnquiryLayer } = useSelector((state) => state.carto.layers);
    const source = useSelector((state) => selectSourceById(state, marketDemandNoEnquiryLayer?.source));
    const cartoLayerProps = useCartoLayerProps({ source });

    if (marketDemandNoEnquiryLayer && source) {
        return new CartoLayer({
            ...cartoLayerProps,
            id: MARKET_DEMAND_NO_ENQUIRY_LAYER_ID,
            getFillColor: (d) => chartColor,
            getLineColor: (d) => chartColorLine,
            pointRadiusMinPixels: 8,
            lineWidthMinPixels: 1,
            pickable: true,
            loadOptions: {
                fetch: {
                    headers: {
                        'cache-control': 'no-cache, max-age=0'
                    }
                }
            },
            onDataLoad: (data) => {},
            onDataError: (error) => { console.log(error) },
        });
    }
}
