import React, { useEffect } from 'react';
import { Modal, Button, Form, Input, Select, message, Tooltip } from 'antd';
import { adminService } from '../admin/admin.service';
import { CopyOutlined } from '@ant-design/icons';

const { Option } = Select;

const UserModal = ({ isVisible, onClose, user, isEditing, setIsEditing, fetchUsers, showTemplateModal, createTemplate }) => {
    const [form] = Form.useForm();
    const [lastUpdatedMessage, setLastUpdatedMessage] = React.useState('');



    useEffect(() => {
        if (isVisible) {
            form.setFieldsValue({
                ...user,
                pms: user.integrations && user.integrations.length > 0 ? user.integrations[0].type : 'None',
            });
            setLastUpdatedMessage(
                user.integrations && user.integrations.length > 0
                ? `Last PMS update at ${new Date(user.integrations[0].updated_at).toLocaleString()}`
                : 'No PMS updates available'
            );
        } else {
            form.resetFields();
        }
    }, [isVisible, user, form]);

    const handleOk = async () => {
        try {
            const values = await form.validateFields();
            await adminService.updateUser(user.id, values);
            message.success('User updated successfully');
            fetchUsers();
            onClose();
            setIsEditing(false);
            form.resetFields();
        } catch (error) {
            message.error('Failed to update user');
        }
    };

    const handleEdit = () => {
        setIsEditing(true);
    };

    const handleCancel = () => {
        onClose();
        setIsEditing(false);
        form.resetFields();
    };

    const handleCopy = (text) => {
        navigator.clipboard.writeText(text);
        message.success('Copied to clipboard');
    };

    return (
        <Modal
            title="Edit User"
            open={isVisible}
            onOk={handleOk}
            onCancel={handleCancel}
            width="80vw"
            height="80vh"
            style={{ top: 20 }}
            bodyStyle={{ overflowY: 'auto', height: 'calc(100% - 55px)' }}
            footer={isEditing ? undefined : [
                <Button key="edit" type="primary" onClick={handleEdit}>
                    Edit
                </Button>,
                user?.template ? (
                    <Button key="template" onClick={showTemplateModal}>
                        View Template
                    </Button>
                ) : (
                    <Button key="create" type="primary" onClick={createTemplate}>
                        Create Template
                    </Button>
                ),
                <Button key="cancel" onClick={handleCancel}>
                    Cancel
                </Button>,
        ]}>
            <Form form={form} layout="vertical" name="form_in_modal" initialValues={user}>
            <Form.Item
                    name="id"
                    label="ID"
                >
                    <Input
                        disabled={true}
                        addonAfter={
                            <Tooltip title="Copy ID">
                                <CopyOutlined onClick={() => handleCopy(user.id)} />
                            </Tooltip>
                        }
                    />
                </Form.Item>
                <Form.Item name="name" label="Name" rules={[{ required: true, message: 'Please input the name!' }]}>
                    <Input disabled={!isEditing} />
                </Form.Item>
                <Form.Item name="email" label="Email" >
                    <Input disabled={true} />
                </Form.Item>
                <Form.Item name="status" label="Status" rules={[{ required: true, message: 'Please select the status!' }]}>
                    <Select disabled={!isEditing}>
                        <Option value="ACTIVE">ACTIVE</Option>
                        <Option value="START">START</Option>
                        <Option value="SETUP">SETUP</Option>
                        <Option value="DISABLED">DISABLED</Option>
                    </Select>
                </Form.Item>
                <Form.Item name="pms" label="PMS" extra={lastUpdatedMessage}>
                    <Input disabled={true} />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default UserModal;
