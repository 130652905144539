import { createSlice } from '@reduxjs/toolkit';

export const demographicsSlice = createSlice({
    name: 'demographics',
    initialState: {
        commutingLayerVisible: true,
        censusPopulationLayerVisible: false,
        medianEarningsLayerVisible: false,
        workFromHomeLayerVisible: false,
    },
    reducers: {
        updateLayerVisibility: (state, action) => {
            const { layer, visibility } = action.payload;

            const layerNames = [
                'commutingLayer',
                'censusPopulationLayer',
                'medianEarningsLayer',
                'workFromHomeLayer',
            ];


            if (!layerNames.includes(layer)) {
                console.error(`La capa "${layer}" no es válida.`);
                return;
            }

            if (visibility) {
                layerNames.forEach((layerName) => {
                    const stateKey = `${layerName}Visible`;
                    state[stateKey] = layerName === layer;
                });
            } else {
                const stateKey = `${layer}Visible`;
                state[stateKey] = false;
            }

        },
    },
});

export const { updateLayerVisibility } = demographicsSlice.actions;

export default demographicsSlice.reducer;
