import { currencyFormatter, numberFormatter, decimalFormatter, precentageFormatter } from './formatter';
import '../modules/carto/components/common/info-window.scss';

const htmlFormatter = (value) => {
    if (value === "#N/A") {
        return value;
    }
    return `<a href="https://www.${value}" target="_blank" rel="noopener noreferrer" class="html-text" >${value}</a>`
}

export const FORMATTER_TYPES = Object.freeze({
    CURRENCY: 'currency',
    NUMBER: 'number',
    DECIMAL: 'decimal',
    RENT_PREMIUM: 'rent_premium',
    URL: 'url',
    PERCENTAGE: 'percentage',
});

const formatterFunctions = {
    [FORMATTER_TYPES.CURRENCY](value) {
        const formatted = currencyFormatter(value);
        return `${formatted.prefix}${formatted.value}`;
    },
    [FORMATTER_TYPES.NUMBER](value) {
        return numberFormatter(value);
    },
    [FORMATTER_TYPES.DECIMAL](value) {
        return decimalFormatter(value);
    },
    [FORMATTER_TYPES.RENT_PREMIUM](value) {
        return `${decimalFormatter(value)}x`;
    },
    [FORMATTER_TYPES.URL](value) {
        return `${htmlFormatter(value)}`;
    },
    [FORMATTER_TYPES.PERCENTAGE](value) {
        return `${precentageFormatter(value)}`;
    },
};

const DEFAULT_FORMATTER = {
    type: '',
    column: '',
};

export default function htmlForFeature({
    title,
    feature,
    formatters = [],
    includeColumns = '*',
    showColumnName = true,
    extraButton = null,
}) {
    if (!feature) {
        throw new Error(`htmlForFeature needs "info.object" information`);
    }

    const propertyNames = Object.keys(feature.properties);

    formatters.forEach(formatter => {
        isFormatterValid(propertyNames, formatter);
    });

    if (!includedColumnsAreValid(propertyNames, includeColumns)) {
        return;
    }

    let html = '<div class="info-content">';

    if (title) {
        html = `<h3 style="margin: 0"><strong>${title}</strong></h3>`;
    }

    if (includeColumns === '*') {
        for (const name of propertyNames) {
            html = generateInnerHtml(name, formatters, includeColumns, showColumnName, feature, html);
        }
    }else {
        for (const column of includeColumns) {
            const columnName = typeof column === 'object' ? column.originalName : column;
            html = generateInnerHtml(columnName, formatters, includeColumns, showColumnName, feature, html);
        }
    }

    if (extraButton) {
        html = html.concat(
            `${extraButton}`
        );
    }

    html = html.concat('</div>')


  return html;
}

function generateInnerHtml(columnName, formatters, includeColumns, showColumnName, feature, html)  {
    if (shouldIncludeColumn(columnName, includeColumns)) {
        const formatter = formatters.find(f => f.column === columnName);
        if (formatter) {
          const formatterFunction = formatterFunctions[formatter.type];
          if (formatterFunction) {
            html = generateHtml(feature, columnName, includeColumns, showColumnName, html, formatterFunction);
          } else {
            console.warn(`Formatter function for type "${formatter.type}" not found.`);
          }
        } else {
          html = generateHtml(feature, columnName, includeColumns, showColumnName, html);
        }
      }
      return html;
}

function shouldIncludeColumn(name, includeColumns) {
    if (includeColumns === '*') {
      return true;
    }

    return includeColumns.some(column => {
        if (typeof column === 'string') {
            return column === name;
        } else if (typeof column === 'object' && column.originalName) {
            return column.originalName === name;
        }
        return false;
    });
  }

function generateHtml(
    feature,
    propertyName,
    includeColumns,
    showColumnName,
    html,
    formatterFunction = (v) => v
) {
    const columnInfo = Array.isArray(includeColumns) ? includeColumns.find(c => typeof c === 'object' && c.originalName === propertyName) : undefined;
    const columnNameToShow = columnInfo ? columnInfo.alias : propertyName;

    return html.concat(
        `${showColumnName ? `<strong>${columnNameToShow}</strong>: ` : ''}${formatterFunction(
        feature.properties[propertyName]
        )}<br/>`
    );
}

function isFormatterValid(properties, formatter) {
    const supportedTypes = Object.values(FORMATTER_TYPES);

    if (!supportedTypes.includes(formatter.type)) {
      throw new Error(
        `"${formatter.type}" is not supported as formatter, use one of "${supportedTypes.join(', ')}"`
      );
    }

    if (!formatter.column) {
      throw new Error(`"formatter.column" property is required`);
    }

    if (!properties.includes(formatter.column)) {
      const available = properties.join(', ');
      throw new Error(
        `"formatter.column" is set to '${formatter.column}' but it was not found!. Available cols are [${available}]`
      );
    }

    return true;
}

function includedColumnsAreValid(properties, includeColumns) {
    if (includeColumns === '*') {
        return true;
    }

    if (!Array.isArray(includeColumns)) {
        throw new Error(`"includeColumns" must be an array of strings or objects`);
    }

    for (const column of includeColumns) {
        const columnName = typeof column === 'object' ? column.originalName : column;
        if (!properties.includes(columnName)) {
            throw new Error(`Column "${columnName}" set in "includeColumns" should exist in the feature`);
        }
    }

    return true;
}

function isArrayOfStrings(value) {
    return Array.isArray(value) && value.length && value.every(String);
}
