import React from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import userStatus from './modules/user/user-status';
import Login from './modules/user/login.component';
import ForgotPassword from './modules/user/forgot-password.component';
import MainDashboard from './components/main-dashboard.component';
import Tab from './modules/template/tab.component';
import MetabaseSubtab from './modules/template/metabase-subtab.component';
import CartoTab from './modules/template/carto-tab.component';
import Start from './modules/start';
import Disabled from './modules/disabled';
import Setup from './modules/setup';
import Account from './modules/user/account.component';
import Admin from './modules/admin/admin.component';
import Users from './modules/admin/users.component';
import Integrations from './modules/integration/integrations.component';
import Profile from './modules/user/profile.component';
import IntegrationsOverview from './modules/integration/integrations-overview.component';
import OfficeRND from './modules/integration/officernd.component';
import Nexudus from './modules/integration/nexudus.component';
import Cobot from './modules/integration/cobot.component';
import Pickspace from './modules/integration/pickspace.component';
import Spacebring from './modules/integration/spacebring.component';
import Manual from './modules/integration/manual.component';
import MarketShare from './modules/carto/components/views/maps/market-share-map.component';
import FlexPrice from './modules/carto/components/views/maps/flex-price-map.component';
import MarketDemand from './modules/carto/components/views/maps/market-demand-map.component';
import FlexArbitrage from './modules/carto/components/views/maps/flex-arbitrage-map.component';
import Demographics from './modules/carto/components/views/maps/demographics-map.component';

export const PublicRoutes = () => {
    const location = useLocation();

    return (
        <Routes>
            <Route path="/" element={<Navigate replace to="/login" />} />
            <Route path="/login" element={<Login />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="*" element={<Navigate replace to={{ pathname: '/login', state: { from: location.pathname } }} />} />
        </Routes>
    );
};

export const PrivateRoutes = ({ profile }) => {
    const defaultPrivateRoute = determineDefaultPrivateRoute(profile);
    const profileStatus = profile.status;
    const profileRole = profile.role;

    function getIntegrationsRoutes(route) {
        return <Route path={route} element={<Integrations /> }>
                <Route path={""} exact element={<IntegrationsOverview integrations={profile.integrations ?? []}/>} />
                {Object.entries(integrationComponents).map(([path, Component]) => (
                    <Route key={path} path={path} element={<Component />} />
                ))}
        </Route>
    }

    const integrationComponents = {
        officernd: OfficeRND,
        nexudus: Nexudus,
        cobot: Cobot,
        pickspace: Pickspace,
        spacebring: Spacebring,
        manual: Manual,
      };

    return (
        <Routes>
            <Route path="/" element={<MainDashboard />}>
                <Route path="start" element={defaultPrivateRoute !== '/start' ? <Navigate replace to={defaultPrivateRoute} /> : <Start profile={profile} />} >
                    { getIntegrationsRoutes("") }
                </Route>
                <Route path="setup/*" element={defaultPrivateRoute !== '/setup' ? <Navigate replace to={defaultPrivateRoute} /> : <Setup profile={profile} />} ></Route>
                <Route path="disabled" element={defaultPrivateRoute !== '/disabled' ? <Navigate replace to={defaultPrivateRoute} /> : <Disabled />} ></Route>
                <Route path="account" element={<Account />} >
                    <Route index element={<Navigate to="profile" replace />} />
                    <Route path="profile" element={<Profile profile={profile} /> }/>
                    { getIntegrationsRoutes("integrations") }
                </Route>
                { profileStatus === userStatus.ACTIVE ?
                    <Route path=":tabId" element={<Tab />}>
                        <Route path="dashboard/:metabaseId" element={<MetabaseSubtab />} />
                        <Route path="map" element={<CartoTab />} >
                            <Route path="Market Share" element={<MarketShare />} />
                            <Route path="Flex Price" element={<FlexPrice />} />
                            <Route path="Market Demand" element={<MarketDemand />} />
                            <Route path="Flex Arbitrage" element={<FlexArbitrage />} />
                            <Route path="Demographics" element={<Demographics />} />
                        </Route>
                    </Route> :
                    <Route path="*" element={<Navigate replace to={defaultPrivateRoute} />} />
                 }
                 { profileRole === 'admin' ?
                    <Route path="admin" element={<Admin />} >
                        <Route index element={<Navigate to="users" replace />} />
                        <Route path="users" element={<Users /> }/>
                    </Route>:
                    <Route path="*" element={<Navigate replace to={defaultPrivateRoute} />}/>
                }
                <Route path="*" element={<Navigate replace to="/" />} />
            </Route>
        </Routes>
    );
};


function determineDefaultPrivateRoute(profile) {
    switch (profile.status) {
        case 'START':
          return '/start';
        case 'SETUP':
          return '/setup';
        case 'DISABLED':
          return '/disabled';
        case 'ACTIVE':
            return '/';
        default:
          return '/disabled';
    }
}
