import React, {useEffect} from 'react';
import {Card, Layout} from 'antd';
import { FrownOutlined } from '@ant-design/icons';
import { Mixpanel } from '../../mixpanel';

const {Content} = Layout;

function Disabled() {

  useEffect(() => {
    Mixpanel.track('Setup - Waiting for data setup');
  });

  return (
    <>
      <Content style={{padding: '24px'}}>
        <Card style={{padding: '24px'}}>
            <div style={{ textAlign: 'center', color: '#AAAAAA'}}>
                <FrownOutlined style={{ fontSize: 50 , marginBottom: '20px'}} />
                <p style={{ fontSize: 24, marginBottom: '0px' }}>Your account is disabled.</p>
                <p style={{ fontSize: 24, marginBottom: '0px' }}> Plase contact <a href="mailto:support@coworkintel.com">support@coworkintel.com</a></p>
            </div>
        </Card>
      </Content>
    </>
  );
}

export default Disabled;
