import React, { useEffect } from 'react';
import { Layout } from 'antd';
import { useLocation, Outlet, useNavigate } from 'react-router-dom';
import SubmenuBar from '../../components/submenu-bar.component';
import { getSubtabType } from '../../utils/utils';


const { Content } = Layout;

function Tab() {
    const location = useLocation();
    const navigate = useNavigate();

    var currentTab = location.state?.currentTab;
    var subSubtabTitle = location.state?.subSubtabTitle;

    useEffect(() => {
        if (!currentTab) {
            navigate('/');
        }

    }, [currentTab, navigate]);

    useEffect(() => {
        const navigateToFirstSubtab = () => {
            const pathSegments = location.pathname.split('/');
            const isSubtabSelected = pathSegments.length > 3 && pathSegments[3] !== '';
            if (!isSubtabSelected && currentTab?.subtabs?.length > 0) {
                let firstSubTab = currentTab.subtabs[0];
                const currentTabIframe = localStorage.getItem("currentDash");

                if (currentTabIframe) {
                    const [tab,,, iframeId] = currentTabIframe.split(':');
                    if (tab === currentTab.tab) {
                        const tempFirstSubTab = currentTab.subtabs.find(subtab => {
                            if (subtab.subtabs && subtab.subtabs.length > 0) {
                                return subtab.subtabs.find(subSubtab => {
                                    return subSubtab.content.id == iframeId;
                                }); // == is intentional
                            }
                            return subtab.content.id == iframeId;
                        }); // == is intentional
                        firstSubTab = tempFirstSubTab || firstSubTab;
                    }
                }
                const type = getSubtabType(firstSubTab);
                const subSubtab = firstSubTab.subtabs && firstSubTab.subtabs.length > 0 ? firstSubTab.subtabs[0] : undefined
                const title = subSubtab ? subSubtab.title : undefined

                navigate(`${type}/${encodeURIComponent(firstSubTab.title)}`, {
                    state: { currentTab: currentTab, subtab: subSubtab ?? firstSubTab, subSubtabTitle:title ?? undefined }
                });
            }
        };

        navigateToFirstSubtab();
    }, [location.pathname, currentTab, navigate]);

    if (!currentTab) {
        return null;
    }

    return (
        <Layout style={{ height: '100%', backgroundColor: '#EBEBEB'}}>
            <SubmenuBar currentTab={currentTab} subSubtabTitle={subSubtabTitle}/>
            <Content>
                <Outlet />
            </Content>

        </Layout>
    );
}

export default Tab;
