import React, {useState, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import BreadcrumbRouter from './breadcrumb-router.component';
import {Typography, Row, Col, Form, Input, Button} from 'antd';
import {integrationService} from './integration.service';
import {getUser} from '../user/user.slice';
import { useNavigate } from 'react-router-dom';
import { Mixpanel } from '../../mixpanel';

const {Text} = Typography;

function Pickspace() {
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  function handleFinish(values) {
    setLoading(true);
    integrationService.connect(Object.assign({type: 'pickspace'}, {credentials: values}))
      .then(() => dispatch(getUser()))
      .then(() => navigate('/setup', { replace: true }))
      .catch((err) => setErrorMessage(err.response.data.message))
      .finally(() => setLoading(false));
  }

  const layout = {labelCol: {span: 8}};

  useEffect(() => {
    Mixpanel.track('Integrations - Pickspace');
  });

  return (
    <>
      <BreadcrumbRouter />

      <h2>Pickspace</h2>
      <p>If you need help connecting your Pickspace account, please check <a href="https://help.coworkintel.com/articles/100266-how-to-connect-coworkintel-and-pickspace" target="_blank" rel="noreferrer">our guide</a>. It's only 2 minutes!</p>

      <p><Text type="danger">{errorMessage}</Text></p>

      <Row>
        <Col lg={6}>
          <Form {...layout} name="connect" onFinish={handleFinish}>
            <Form.Item
              label="Auth Token"
              name="auth_token"
              rules={[{required: true, message: 'Please input your Authentication Token'}]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Space Name"
              name="organization"
              rules={[{required: true, message: 'Please input your Space Name'}]}
            >
              <Input />
            </Form.Item>
            <p><small>Learn about how Coworkintel, Inc. will handle your data by reviewing  our <a href="https://coworkintel.com/terms.html" target="_blank" rel="noreferrer">terms of service</a>, <a href="https://coworkintel.com/privacy-policy.html" target="_blank" rel="noreferrer">privacy policies</a> and <a href="https://coworkintel.com/policy.html" target="_blank" rel="noreferrer">competitive set policy</a>. You can always see or remove access in your Pickspace Account.</small></p>
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={isLoading}>Connect</Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </>
  );
}

export default Pickspace;
